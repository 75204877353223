export const Collections = {
  SERVICES: 'services',
  DEPARTMENTS: 'departments',
  HOTELS: 'hotels',
  HOTELGUIDELINES: 'hotelGuidelines',
  ORDERS: 'orders',
  ROLES: 'roles',
  USERS: 'users',
  RESTAURANT: 'restaurant',
  TERMSANDCONDITIONS: 'termsAndConditions',
  PRIVACYPOLICY: 'privacyPolicy',
  CUISINES: 'cuisines',
  FOODMENU: 'foodMenu',
  DOMAIN: 'domain',
  GUEST: 'guest',
  HOTELSHUTTLE: 'hotelShuttle',
  LANGUAGEDICTIONARY: 'languagedictionary',
  NOTIFICATIONS: 'Notifications',
  SPA: 'spa',
  SALOON: 'saloon',
  GYM: 'gym',
  OVERALLFEEDBACKQUESTIONS: 'overallFeedbackQuestions',
  HOTELOVERALLFEEDBACKQUESTIONS: 'hotelOverallFeedbackQuestions',
  HOTELFEEDBACKS: 'hotelFeedbacks',
  VERSIONS: 'versions',
  ADMINSETTINGS: 'adminSettings',
  ADMIN_GUESTSETTINGS: 'admin_GuestSettings',
  FLEXIPASSHOTELSETTINGS: 'flexiPassHotelSettings',
}

/*
    Redirect to /home if logged in otherwise open the given route
*/

import React, { memo, Suspense } from 'react'
import { Route } from 'react-router-dom'
import { Loader } from '../components/Common/Loader'
import OfflinePage from '../components/Pages/OfflinePage'
import { AppContext } from '../config/context'
import { networkStatus } from '../constants/general'
import CommonResponseModal from '../components/Common/Modals/CommonResponseModal'

const PublicRoute = ({ render: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <AppContext.Consumer>
            {context => {
              if (context.appState.online === networkStatus.FAILED) {
                return <OfflinePage />
              }

              return (
                <Suspense fallback={<Loader />}>
                  <Component {...props} {...rest} />
                  <CommonResponseModal />
                </Suspense>
              )
            }}
          </AppContext.Consumer>
        )
      }}
    />
  )
}

export default memo(PublicRoute)

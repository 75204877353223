/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { maintenanceImg } from '../../../config/images'
import { Logout } from '../../../config/utils'
import routeLink from '../../../constants/routeLink'

const Maintenance = () => {
  const dispatch = useDispatch()
  const { production } = useSelector(state => state)
  const { showMaintenancePage = false } = production

  useEffect(() => {
    Logout({ dispatch, routeLinkObj: routeLink.Hotel })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!showMaintenancePage) {
      navigator.serviceWorker.dispatchEvent(
        new CustomEvent('controllerchange', { detail: true })
      )
    }
  }, [showMaintenancePage])

  return (
    <>
      <div className='maintenance-wrp'>
        <div>
          <figure>
            <img className='img-fluid' src={maintenanceImg}></img>
          </figure>

          <h3>Website under maintenance</h3>
          <h3>
            Inplass web and mobile applications will not be available during
            this time. Our apology for the inconvenience.
          </h3>
        </div>
      </div>
    </>
  )
}

export default Maintenance

import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useCustomI18NTranslatorHook } from '../../../utility/globalization'
import { Ternary } from '../../../config/utils'
import { actions } from '../../../Store'
import { failedcross, successtick } from '../../../config/images'

const CommonResponseModal = () => {
  const dispatch = useDispatch()
  const {
    commonResponseModal: {
      visible = false,
      success = false,
      messageText = '',
      options = { close: () => {} },
    },
  } = useSelector(state => state)

  const [translateTextI18N] = useCustomI18NTranslatorHook()

  const onCancleClick = useCallback(
    e => {
      e.preventDefault()

      dispatch(
        actions.setCommonResponseModal({
          visible: false,
          success: false,
          messageText: '',
          options: {},
        })
      )

      options?.close?.()
    },
    [dispatch, options]
  )

  if (!visible) return null

  return (
    <>
      <div className={`CmnModal-wrp responseModal ${visible ? 'open' : ''}`}>
        <div className='modalContent'>
          <div className='modalBody'>
            <div className='status-img'>
              <img
                alt=''
                src={Ternary(success, successtick, failedcross)}
              ></img>
            </div>
            <h6 style={{ color: 'black' }}>
              {options
                ? translateTextI18N(messageText, options)
                : translateTextI18N(messageText)}
            </h6>
          </div>

          <div className='modalFooter'>
            <div className='footerbtn-wrp'>
              <button className='cmnBtn mx-1' onClick={onCancleClick}>
                {translateTextI18N('Close')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CommonResponseModal

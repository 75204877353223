import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { AppContext } from '../config/context'
import { AddDepartmentListener } from '../services/department'
import { GetLanguageDictionary } from '../services/languageDictionary'
import { actions } from '../Store'
import { AddServicesListener } from '../services/service'
import { Loader } from '../components/Common/Loader'
import { dispatchSetLanguage, setCurrentHotelKey } from '../config/utils'
import { history } from '../history'
import routeLink from '../constants/routeLink'
import { MaintenanceListerner } from '../services/maintenance'
import Maintenance from '../components/Pages/Maintenance/Maintenance'
import QRScreen from '../components/Pages/QRScreen'
import useDetectMobile from './useDetectMobile'

function AppProviderWrapper(props) {
  const appState = useSelector(state => state)
  const {
    hotelInfo,
    currentLanguage,
    flatLanguageData,
    previousLanguage,
    googleLangCode,
    departmentListenerAdded,
    servicesListenerAdded,
    isLanguageListenerAdded,
    production,
    isAppLoaded,
    isScanned,
    currentHotelKey,
    guest,
    online,
    appStatus,
    subDomain,
    logoutFrom,
    UrlLoader,
  } = appState
  const dispatch = useDispatch()
  const { showMaintenancePage } = production
  const [deviceTypeId, loadDetectMobile] = useDetectMobile()

  useEffect(() => {
    GetLanguageDictionary(isLanguageListenerAdded, dispatch)
  }, [dispatch, isLanguageListenerAdded])

  useEffect(() => {
    i18n.use(initReactI18next).init({
      keySeparator: false,
      resources: flatLanguageData,
      lng: currentLanguage,
      interpolation: {
        escapeValue: false,
      },
    })
  }, [currentLanguage, flatLanguageData])

  useEffect(() => {
    dispatchSetLanguage(dispatch, {
      currentLanguage,
      previousLanguage,
      googleLangCode,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage, previousLanguage, googleLangCode])

  useEffect(() => {
    AddDepartmentListener(dispatch, hotelInfo?.id, departmentListenerAdded)
  }, [dispatch, hotelInfo?.id, departmentListenerAdded])

  useEffect(() => {
    AddServicesListener(hotelInfo?.id, dispatch, servicesListenerAdded)
  }, [hotelInfo?.id, dispatch, servicesListenerAdded])

  useEffect(() => {
    let isStanAlone = window.matchMedia('(display-mode: standalone)').matches
    if (isStanAlone) {
      document.body.style.overscrollbheaviorY = 'contain'
    }
    dispatch(actions.setIsPWA(isStanAlone))
  }, [dispatch])

  useEffect(() => {
    navigator?.serviceWorker?.dispatchEvent(
      new CustomEvent('controllerchange', { detail: false })
    )
    MaintenanceListerner(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const memo = useMemo(() => {
    if (loadDetectMobile || !isAppLoaded) return <Loader />

    if (showMaintenancePage) return <Maintenance />

    let getHotelKey = window.location.pathname
      .replace('/hotel/', '')
      .replace('/', '')
    if (window.location.pathname.includes('/hotel/') && getHotelKey) {
      setCurrentHotelKey(dispatch, getHotelKey)
      history.replace(routeLink.Hotel)
    }

    if (
      !currentHotelKey &&
      !isScanned &&
      deviceTypeId !== 'Other' &&
      !hotelInfo?.id &&
      !localStorage.getItem('hotel') &&
      !UrlLoader
    )
      return <QRScreen />

    return (
      <AppContext.Provider
        value={{
          appState: {
            guest,
            online,
            appStatus,
            currentHotelKey,
            subDomain,
            logoutFrom,
          },
        }}
      >
        {props.children}
      </AppContext.Provider>
    )
  }, [
    loadDetectMobile,
    isAppLoaded,
    showMaintenancePage,
    currentHotelKey,
    isScanned,
    deviceTypeId,
    hotelInfo?.id,
    guest,
    online,
    appStatus,
    subDomain,
    logoutFrom,
    props.children,
    dispatch,
    UrlLoader,
  ])

  return memo
}

export default AppProviderWrapper

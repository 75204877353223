import React, { useEffect } from 'react'
import LottieAnimation from './LottieAnimation'
import home from './animation.json'

const HotelNotFound = ({ message }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      if (localStorage.getItem('hotel')) {
        window.location.reload()
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className='content-loader'>
      <LottieAnimation lotti={home} height={300} width={350} />
      <h6 style={{ paddingTop: '30px', textAlign: 'center' }}>{message}</h6>
    </div>
  )
}

export default HotelNotFound

const hideMenu = e => {
  e.stopPropagation()
  document.body.classList.remove('menuOpen')
  if (document.getElementById('BckBtn'))
    document.getElementById('BckBtn').classList.remove('newClass')
  const deptDisabled = document.querySelectorAll('#deptClass')
  for (const element of deptDisabled) {
    element.classList.remove('disableClickDept')
  }
}
export default hideMenu

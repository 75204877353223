import React, { memo, Suspense } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { Loader } from '../components/Common/Loader'
import ProductionDetailsToast from '../components/Common/ProductionDetailsToast/ProductionDetailsToast'
import OfflinePage from '../components/Pages/OfflinePage'
import { AppContext } from '../config/context'
import {
  guestAppStatus,
  loadProgress,
  networkStatus,
  searchingHotelMessages,
} from '../constants/general'
import GuestStatus from '../constants/GuestStatus'
import routeLink from '../constants/routeLink'
import { actions } from '../Store'

function handlePageRoute({
  render: Component,
  dispatch,
  appStatus,
  guest,
  props,
  rest,
}) {
  let ls_apitoken = localStorage.getItem('apitoken')
  let ls_guestId = localStorage.getItem('guestId')

  if (!ls_guestId && !ls_apitoken) {
    dispatch(
      actions.setSearchingHotel({
        searchingHotel: loadProgress.LOADED,
        searchingHotelMessage: searchingHotelMessages.NOT_AVAILABLE,
      })
    )
    return <Redirect to={routeLink.Hotel} />
  } else {
    if (!('status' in guest)) {
      if (appStatus === guestAppStatus.CheckOut) {
        return <Redirect to={routeLink.IntermediateScreen} />
      }

      return <Redirect to={routeLink.Hotel} />
    }
  }
  return (
    <Suspense fallback={<Loader />}>
      <ProductionDetailsToast />
      <Component {...props} {...rest} {...guest} />
    </Suspense>
  )
}

const ConfirmationCheckPointRoute = ({ render, ...rest }) => {
  const dispatch = useDispatch()

  const RouteRenderComponent = props => {
    return (
      <AppContext.Consumer>
        {context => {
          const { guest, online, appStatus } = context.appState
          let ls_apitoken = localStorage.getItem('apitoken')
          let ls_hotel = localStorage.getItem('hotel')
          let ls_guestId = localStorage.getItem('guestId')

          if (ls_guestId && ls_hotel && online === networkStatus.FAILED) {
            return <OfflinePage />
          }

          if (
            ls_apitoken &&
            !ls_guestId &&
            ls_hotel &&
            guest?.status === GuestStatus.CheckIn
          ) {
            return <Redirect to={routeLink.Home} />
          } else {
            return handlePageRoute({
              render,
              dispatch,
              appStatus,
              guest,
              props,
              rest,
            })
          }
        }}
      </AppContext.Consumer>
    )
  }
  return <Route {...rest} render={RouteRenderComponent} />
}

export default memo(ConfirmationCheckPointRoute)

import { timestamp } from '../config/firebase'

export const creationData = () => {
  return {
    isDelete: false,
    createdAt: timestamp,
    updatedAt: timestamp,
  }
}

export const updatationData = () => {
  return {
    updatedAt: timestamp,
  }
}

import { Collections } from '../config/Constants'
import { auth, db } from '../config/firebase'
import { unsubscribeList } from '../config/utils'
import { actions } from '../Store'

export const AddServicesListener = (
  hotelId,
  dispatch,
  servicesListenerAdded
) => {
  try {
    const collectionKey = `AddRoomTypeListener${Collections.SERVICES}${hotelId}`
    if (
      !auth?.currentUser ||
      !hotelId ||
      servicesListenerAdded ||
      unsubscribeList?.[collectionKey]
    )
      return

    dispatch(actions.setServicesListenerAdded(true))

    const unSub = db
      .collection(Collections.SERVICES)
      .where('hotelId', '==', hotelId)
      .where('isDelete', '==', false)
      .onSnapshot(servicesSnapshot => {
        const services = []
        for (const service of servicesSnapshot.docs) {
          services.push({ id: service.id, ...service.data() })
        }
        dispatch(actions.setServices(services))
      })

    unsubscribeList[collectionKey] = unSub
  } catch (error) {
    console.log(error)
  }
}

import {
  createSlice,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import { clone } from 'underscore'
import _ from 'lodash'

import { AddIndex, Sort } from '../config/utils'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  authtoken,
  guestId,
  language,
  tmplanguage,
  loadProgress,
  searchingHotelMessages,
  langClassName,
  langList,
  guestAppStatus,
  networkStatus,
} from '../constants/general'
import GuestStatus from '../constants/GuestStatus'

const CalcDepartmentAndServiceKeyToName = (state, data) => {
  const keyToId = { ...state.departmentAndServiceKeyToName }
  const idToInfo = { ...state.departmentAndServiceIdToInfo }
  const keyToStatus = { ...state.departmentAndServiceKeyToStatus }

  data.forEach(({ key, id, active, ...rest }) => {
    keyToId[key] = id
    keyToStatus[key] = active
    idToInfo[id] = { id, key, active, ...rest }
  })

  state.departmentAndServiceKeyToName = keyToId
  state.departmentAndServiceKeyToStatus = keyToStatus
  state.departmentAndServiceIdToInfo = idToInfo
}

const ResetListener = state => {
  state.roomTypeListenerAdded = initialState.roomTypeListenerAdded
  state.roomTypes = initialState.roomTypes

  state.GetHotelDataFromIdListener = initialState.GetHotelDataFromIdListener
  state.isNotificationListener = initialState.isNotificationListener

  state.cuisinesListenerAdded = initialState.cuisinesListenerAdded
  state.cuisines = initialState.cuisines

  state.foodMenuListenerAdded = initialState.foodMenuListenerAdded
  state.foodMenu = initialState.foodMenu

  state.promotionsListenerAdded = initialState.promotionsListenerAdded
  state.promotionsListenerAddedTwo = initialState.promotionsListenerAddedTwo
  state.promotionsListenerAddedThree = initialState.promotionsListenerAddedThree
  state.promotions = initialState.promotions
  state.promotionsTwo = initialState.promotionsTwo
  state.promotionsThree = initialState.promotionsThree

  state.cuisineMenuListenerAdded = initialState.cuisineMenuListenerAdded
  state.cuisineMenu = initialState.cuisineMenu

  state.restaurantListenerAdded = initialState.restaurantListenerAdded
  state.restaurant = initialState.restaurant

  state.hotelShuttleListenerAdded = initialState.hotelShuttleListenerAdded
  state.hotelShuttle = initialState.hotelShuttle

  state.spaListenerAdded = initialState.spaListenerAdded
  state.spa = initialState.spa

  state.saloonListenerAdded = initialState.saloonListenerAdded
  state.saloon = initialState.saloon

  state.gymListenerAdded = initialState.gymListenerAdded
  state.gym = initialState.gym

  state.servicesListenerAdded = initialState.servicesListenerAdded
  state.services = initialState.services

  state.departmentListenerAdded = initialState.departmentListenerAdded
  state.departmentsNew = initialState.departmentsNew

  state.servicesListenerAdded = initialState.servicesListenerAdded
  state.services = initialState.services

  state.departmentListenerAdded = initialState.departmentListenerAdded
  state.departmentsNew = initialState.departmentsNew

  state.servicesNew = initialState.servicesNew
  state.departmentAndServiceKeyToName =
    initialState.departmentAndServiceKeyToName
  state.departmentAndServiceIdToInfo = initialState.departmentAndServiceIdToInfo
  state.departmentAndServiceKeyToStatus =
    initialState.departmentAndServiceKeyToStatus

  state.duplicateRequest = initialState.duplicateRequest
  state.duplicateRequestServiceName = initialState.duplicateRequestServiceName
}

const initialState = {
  currentLanguage: language || 'en',
  googleLangCode: tmplanguage || 'en',
  flatLanguageData: {},
  gettingHotelLogo: false,
  guest: {},
  hotelId: '',
  showUnsubscribeModal: false,
  hotelInfo: {},
  hotelLogo: null,
  hotelWallpaper: null,
  languageDictionary: [],
  languageList: [],
  previousLanguage: 'en',
  referanceNumber: null,
  sideMenuOpenKeys: [],
  sideMenuSelectedKey: '10',
  subDomain: null,
  notifications: [],

  roomTypeListenerAdded: false,
  roomTypes: [],

  subDomainNotFound: false,
  userInfo: null,
  searchingHotel: loadProgress.TOLOAD,
  searchingHotelMessage: searchingHotelMessages.LOADING,
  guestId: guestId,
  apiToken: authtoken,
  GetHotelDataFromIdListener: false,
  isGuestCheckinListener: false,
  billAmount: 0,
  isNotificationListener: false,

  loadingCuisines: false,
  cuisinesListenerAdded: false,
  cuisines: [],
  loadingFoodMenu: false,
  foodMenuListenerAdded: false,
  foodMenu: [],
  cart: {},
  cartTwo: {},
  chargeableCart: [],
  unavailableItemsCountInCart: 0,

  promotionsListenerAdded: false,
  promotionsListenerAddedTwo: false,
  promotionsListenerAddedThree: false,
  promotions: [],
  promotionsTwo: [],
  promotionsThree: [],
  selectedPromotion: [],
  cuisineMenuListenerAdded: false,
  cuisineMenu: [],

  selectedCuisine: '',
  selectedCuisineMenu: {},
  loadingRestaurants: false,
  restaurantListenerAdded: false,
  restaurant: [],
  selectedRestaurant: {},

  hotelShuttleListenerAdded: false,
  hotelShuttle: [],
  loadingHotelShuttle: false,

  spaListenerAdded: false,
  spa: [],
  loadingSpa: false,

  saloonListenerAdded: false,
  saloon: [],
  loadingSaloon: false,

  gymListenerAdded: false,
  gym: [],
  loadingGym: false,

  duplicateRequest: false,
  duplicateRequestServiceName: '',
  redirect: false,

  servicesListenerAdded: false,
  services: [],
  departmentListenerAdded: false,
  departmentsNew: [],
  allDepartmentsNew: [],
  servicesNew: {},
  allServicesNew: [],
  departmentAndServiceKeyToName: {},
  departmentAndServiceIdToInfo: {},
  departmentAndServiceKeyToStatus: {},
  appStatus: guestAppStatus.Initial,
  online: networkStatus.TOLOAD,
  isPWA: false,
  manifestFileData: null,
  currentHotelKey: null,
  logoutFrom: null,
  hotelGuideLines: {
    data: [],
    loadingStatus: loadProgress.TOLOAD,
  },
  production: {
    enableStickyBar: false,
    showMaintenancePage: false,
    fromDate: null,
    toDate: null,
  },
  appVersion: '1',
  isAppLoaded: false,
  isScanned: false,
  ratingEmoji: {
    data: {},
    loadingStatus: loadProgress.TOLOAD,
  },
  completedRequest: {
    loadingStatus: loadProgress.TOLOAD,
    data: [],
  },
  serviceFeedbackModal: {
    visible: false,
    data: null,
    isEdit: false,
  },
  commonResponseModal: {
    visible: false,
    success: false,
    messageText: '',
    options: {},
  },
  overAllFeedbackQuestion: {
    data: [],
    loadingStatus: loadProgress.TOLOAD,
  },
  curGuestPreviousFeedback: {
    data: [],
    loadingStatus: loadProgress.TOLOAD,
  },
  loggedOutGuestId: '',
  versionNumber: '',
  userName: '',
  guestSettingsList: [],
  guestSettingsLoading: false,
  UrlLoader: false,
  guestFromUrl: null,
  hotelFromUrl: null,
  hotelDoorPresent: false,
}

const slice = createSlice({
  name: 'InPlass Hotel Admin',
  initialState,
  reducers: {
    setLoggedOutGuestId: (state, action) => {
      state.loggedOutGuestId = action.payload
    },
    setCurGuestPreviousFeedback: (state, action) => {
      state.curGuestPreviousFeedback.loadingStatus =
        action.payload.loadingStatus
      if (action.payload?.data) {
        state.curGuestPreviousFeedback.data = action.payload?.data
      }
    },
    setOverAllFeedbackQuestion: (state, action) => {
      state.overAllFeedbackQuestion.loadingStatus = action.payload.loadingStatus

      if (action.payload?.data) {
        state.overAllFeedbackQuestion.data = action.payload?.data
      }
    },
    setCommonResponseModal: (state, action) => {
      state.commonResponseModal = action.payload
    },
    setServiceFeedbackModal: (state, action) => {
      if ('visible' in action.payload) {
        state.serviceFeedbackModal.visible = action.payload.visible
      }

      if ('data' in action.payload) {
        state.serviceFeedbackModal.data = action.payload.data
      }

      if ('isEdit' in action.payload) {
        state.serviceFeedbackModal.isEdit = action.payload.isEdit
      }
    },
    setCompletedRequest: (state, action) => {
      if ('loadingStatus' in action.payload) {
        state.completedRequest.loadingStatus = action.payload.loadingStatus
      }

      if ('data' in action.payload) {
        state.completedRequest.data = action.payload.data
      }
    },
    setRatingEmoji: (state, action) => {
      if ('loadingStatus' in action.payload) {
        state.ratingEmoji.loadingStatus = action.payload.loadingStatus
      }

      if ('data' in action.payload) {
        state.ratingEmoji.data = action.payload.data
      }
    },
    setGuestAuthData: (state, action) => {
      state.guestAuthData = action.payload
    },
    setHotelData: (state, action) => {
      state.subDomain = action.payload.subDomain
      state.hotelInfo = action.payload.hotelInfo

      state.subDomainNotFound = action.payload.subDomainNotFound
      state.gettingHotelLogo = false

      state.hotelLogo = action.payload.hotelLogo
      state.hotelWallpaper = action.payload.hotelWallpaper
      state.gettingHotelLogo = false

      state.searchingHotel = action.payload.searchingHotel
      state.searchingHotelMessage = action.payload.searchingHotelMessage
    },
    setHotelInfoWithQrCode: (state, action) => {
      state.hotelInfo = action.payload.hotelInfo
      state.searchingHotelMessage = searchingHotelMessages.SUCCESS
      state.searchingHotel = loadProgress.LOADED
      state.isScanned = action.payload.isScanned
    },
    setIsScanned: (state, action) => {
      state.isScanned = action.payload
    },
    setIsAppLoaded: (state, action) => {
      state.isAppLoaded = action.payload
    },
    setCurrentHotelKey: (state, action) => {
      state.currentHotelKey = action.payload
    },
    setProduction: (state, action) => {
      state.production = { ...state.production, ...action.payload }
      state.isAppLoaded = true
    },
    setAppVersion: (state, action) => {
      state.appVersion = action.payload
    },
    setManifestFileData: (state, action) => {
      state.manifestFileData = action.payload
    },
    setIsPWA: (state, action) => {
      state.isPWA = action.payload
    },
    setOnline: (state, action) => {
      state.online = action.payload
    },
    setAppStatus: (state, action) => {
      const {
        appStatus,
        guestStatus,
        subDomain,
        logoutFrom = null,
      } = action.payload
      if (appStatus) {
        state.appStatus = appStatus
      }

      if (guestStatus) {
        state.guest = { ...state.guest, status: guestStatus }
      }

      if (subDomain) {
        state.subDomain = subDomain
      }

      state.logoutFrom = logoutFrom
    },
    setRedirect: (state, action) => {
      state.redirect = action.payload
    },
    setLogout: (state, action) => {
      state.userInfo = {}
      state.hotelId = ''
      state.guest = {}
      state.isGuestCheckinListener = false
      state.GetHotelDataFromIdListener = false

      state.guestStatus = GuestStatus.CheckOut
      state.appStatus = guestAppStatus.CheckOut

      if (action.payload?.subDomain) {
        state.subDomain = action.payload.subDomain
      }

      if (action.payload?.logoutFrom) {
        state.logoutFrom = action.payload.logoutFrom
      }

      state.searchingHotel =
        action?.payload?.searchingHotel ?? loadProgress.TOLOAD
      state.searchingHotelMessage =
        action?.payload?.searchingHotelMessages ??
        searchingHotelMessages.LOADING

      ResetListener(state)
    },
    setClearHotelData: state => {
      state.hotelId = ''
      state.guest = {}
      state.hotelInfo = {}
      state.subDomain = ''
      ResetListener(state)
    },
    setClearHotelUserData: (state, action) => {
      state.appStatus = guestAppStatus.Initial
      state.userInfo = {}
      state.hotelId = ''
      state.guest = {}
      state.hotelInfo = {}
      state.isGuestCheckinListener = false
      state.GetHotelDataFromIdListener = false
      state.logoutFrom = 'PrivateLayout'
      state.guestStatus = GuestStatus.CheckOut
      state.searchingHotel =
        action?.payload?.searchingHotel ?? loadProgress.TOLOAD
      state.searchingHotelMessage =
        action?.payload?.searchingHotelMessage ?? searchingHotelMessages.LOADING

      localStorage.removeItem('guestId')
      localStorage.removeItem('apitoken')
      ResetListener(state)
    },
    setClearUserData: (state, action) => {
      state.appStatus = guestAppStatus.Initial
      state.userInfo = {}
      state.hotelId = ''
      state.guest = {}
      state.isGuestCheckinListener = false
      state.GetHotelDataFromIdListener = false
      state.searchingHotel =
        action?.payload?.searchingHotel ?? loadProgress.TOLOAD
      state.searchingHotelMessage =
        action?.payload?.searchingHotel ?? searchingHotelMessages.LOADING

      localStorage.removeItem('guestId')
      localStorage.removeItem('apitoken')
      ResetListener(state)
    },
    setGetHotelDataFromIdListener: (state, action) => {
      state.GetHotelDataFromIdListener = action.payload
    },
    setGuestCheckinListener: (state, action) => {
      state.isGuestCheckinListener = action.payload
    },
    setUserName: (state, action) => {
      state.userName = action.payload
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
      state.hotelId = action.payload?.hotelId
    },
    setGuest: (state, action) => {
      const lang = action?.payload?.language ?? state.currentLanguage
      state.guest = action.payload
      state.currentLanguage = lang
      localStorage.setItem('language', lang)
    },
    setHotelInfo: (state, action) => {
      state.hotelInfo = action.payload
    },
    setHotelId: (state, action) => {
      state.hotelId = action.payload
    },
    setSideMenuSelectedKey: (state, action) => {
      state.sideMenuSelectedKey = action.payload
      if (Array.isArray(action.payload) && action.payload.includes('sub1'))
        state.sideMenuOpenKeys = ['sub1']
    },
    setSideMenuOpenKeys: (state, action) => {
      if (state.sideMenuOpenKeys.includes(action.payload))
        state.sideMenuOpenKeys = state.sideMenuOpenKeys.filter(
          k => k !== action.payload
        )
      else state.sideMenuOpenKeys.push(action.payload)
    },
    setSubDomain: (state, action) => {
      state.subDomain = action.payload
    },
    setSubDomainNotFound: (state, action) => {
      state.subDomainNotFound = action.payload
      state.gettingHotelLogo = false
    },
    setGettingHotelLogo: state => {
      state.gettingHotelLogo = true
    },
    setHotelLogo: (state, action) => {
      state.hotelLogo = action.payload.hotelLogo
      state.hotelWallpaper = action.payload.hotelWallpaper
      state.gettingHotelLogo = false
    },
    setRoomTypeListenerAdded: (state, action) => {
      state.roomTypeListenerAdded = action.payload
    },
    setRoomTypes: (state, action) => {
      state.roomTypes = AddIndex(action.payload)
    },
    setLanguageConfig: (state, action) => {
      const currentLanguage = action.payload?.currentLanguage || 'en'
      state.guest = { ...state.guest, language: currentLanguage }
      state.currentLanguage = currentLanguage
      state.previousLanguage = action.payload?.previousLanguage || 'en'
      state.googleLangCode = action.payload?.googleLangCode
        ? action.payload?.googleLangCode
        : 'en'
      localStorage.setItem('language', action.payload.currentLanguage ?? 'en')
      localStorage.setItem('tmplanguage', action.payload.googleLangCode ?? 'en')

      let sClassName = ''
      switch (action.payload?.currentLanguage) {
        case langList.ml:
          sClassName = langClassName.ml
          break
        case langList.ar:
          sClassName = langClassName.ar
          break
        case langList.ur:
          sClassName = langClassName.ur
          break
        default:
          break
      }
      document.body.classList.remove(langClassName.ar)
      document.body.classList.remove(langClassName.ml)
      document.body.classList.remove(langClassName.ur)
      if (sClassName) document.body.classList.add(sClassName)
    },
    setFetchLanguageDictionary: (state, action) => {
      state.languageList = action.payload.languageList
      state.languageDictionary = action.payload.languageDictionary
      state.flatLanguageData = action.payload.flatLanguageData
    },
    setSearchingHotel: (state, action) => {
      state.searchingHotel = action.payload.searchingHotel
      state.searchingHotelMessage = action.payload.searchingHotelMessage
    },
    setBillAmount: (state, action) => {
      state.billAmount = action.payload
    },
    setLoadingCuisines: (state, action) => {
      state.loadingCuisines = action.payload
    },
    setCuisinesListenerAdded: (state, action) => {
      state.cuisinesListenerAdded = action.payload
    },
    setCuisines: (state, action) => {
      state.cuisines = action.payload
    },
    setLoadingFoodMenu: (state, action) => {
      state.loadingFoodMenu = action.payload
    },
    setFoodMenuListenerAdded: (state, action) => {
      state.foodMenuListenerAdded = action.payload
    },
    setFoodMenu: (state, action) => {
      const foodMenu = action.payload
      state.foodMenu = foodMenu

      const cartClone = _.cloneDeep(state.cart)
      const keys = Object.keys(cartClone)
      let unAvailableCount = 0
      if (keys.length) {
        keys.forEach(cuisineId => {
          Object.keys(cartClone[cuisineId]).forEach(menuId => {
            const menuItem = foodMenu.find(f => f.id === menuId)
            if (menuItem) {
              cartClone[cuisineId][menuId] = {
                ...cartClone[cuisineId][menuId],
                ...menuItem,
              }
            } else {
              cartClone[cuisineId][menuId] = {
                ...cartClone[cuisineId][menuId],
                available: false,
              }
            }
            if (!menuItem?.available) unAvailableCount++
          })
        })
      }
      state.unavailableItemsCountInCart = unAvailableCount
      state.cart = cartClone
    },
    setCart: (state, action) => {
      state.cart = action.payload
    },
    setCartTwo: (state, action) => {
      state.cartTwo = action.payload
    },
    setChargeableCart: (state, action) => {
      state.chargeableCart = action.payload
    },
    setUnavailableItemsCountInCart: (state, action) => {
      state.unavailableItemsCountInCart = action.payload
    },
    ClearCart: state => {
      state.cart = {}
      state.unavailableItemsCountInCart = 0
    },
    setPromotionsListenerAdded: (state, action) => {
      state.promotionsListenerAdded = action.payload
    },
    setPromotionsListenerAddedTwo: (state, action) => {
      state.promotionsListenerAddedTwo = action.payload
    },
    setPromotionsListenerAddedThree: (state, action) => {
      state.promotionsListenerAddedThree = action.payload
    },
    setPromotions: (state, action) => {
      state.promotions = action.payload
    },
    setPromotionsTwo: (state, action) => {
      state.promotionsTwo = action.payload
    },
    setPromotionsThree: (state, action) => {
      state.promotionsThree = action.payload
    },
    setSelectedPromotion: (state, action) => {
      state.selectedPromotion = action.payload
    },
    setCuisineMenuListenerAdded: (state, action) => {
      state.cuisineMenuListenerAdded = action.payload
    },
    setCuisineMenu: (state, action) => {
      state.cuisineMenu = action.payload
    },
    setSelectedCuisineMenu: (state, action) => {
      state.selectedCuisineMenu = action.payload
    },
    setSelectedCuisine: (state, action) => {
      state.selectedCuisine = action.payload
    },
    setLoadingRestaurants: (state, action) => {
      state.loadingRestaurants = action.payload
    },
    setRestaurantListenerAdded: (state, action) => {
      state.restaurantListenerAdded = action.payload
    },
    setRestaurant: (state, action) => {
      state.restaurant = action.payload
      if (state.selectedRestaurant.id) {
        const restaurant = action.payload.find(
          r => r.id === state.selectedRestaurant.id
        )
        if (restaurant) {
          state.selectedRestaurant = { ...restaurant }
        } else {
          state.selectedRestaurant = initialState.selectedRestaurant
        }
      }
    },
    setHotelShuttleListenerAdded: (state, action) => {
      state.hotelShuttleListenerAdded = action.payload
    },
    setHotelShuttle: (state, action) => {
      state.hotelShuttle = action.payload
    },
    setLoadingHotelShuttle: (state, action) => {
      state.loadingHotelShuttle = action.payload
    },
    setSpaListenerAdded: (state, action) => {
      state.spaListenerAdded = action.payload
    },
    setSpa: (state, action) => {
      state.spa = action.payload
    },
    setLoadingSpa: (state, action) => {
      state.loadingSpa = action.payload
    },
    setSaloonListenerAdded: (state, action) => {
      state.saloonListenerAdded = action.payload
    },
    setSaloon: (state, action) => {
      state.saloon = action.payload
    },
    setLoadingSaloon: (state, action) => {
      state.loadingSaloon = action.payload
    },
    setGymListenerAdded: (state, action) => {
      state.gymListenerAdded = action.payload
    },
    setGym: (state, action) => {
      state.gym = action.payload
    },
    setLoadingGym: (state, action) => {
      state.loadingGym = action.payload
    },
    setSelectedRestaurant: (state, action) => {
      state.selectedRestaurant = action.payload
    },
    setNotificationListener: (state, action) => {
      state.isNotificationListener = action.payload
    },
    setNotification: (state, action) => {
      state.notifications = action.payload
    },
    setDuplicateRequest: (state, action) => {
      state.duplicateRequest = action.payload.duplicateRequest
      state.duplicateRequestServiceName =
        action.payload.duplicateRequestServiceName
    },
    setServicesListenerAdded: (state, action) => {
      state.servicesListenerAdded = action.payload
    },
    setServices: (state, action) => {
      state.services = Sort(action.payload, 'index')
      CalcDepartmentAndServiceKeyToName(state, action.payload)
    },
    setDepartmentListenerAdded: (state, action) => {
      state.departmentListenerAdded = action.payload
    },
    setDepartmentsNew: (state, action) => {
      state.departmentsNew = action.payload
      CalcDepartmentAndServiceKeyToName(state, action.payload)
    },
    setServicesNew: (state, action) => {
      const cloned = clone(state.servicesNew)
      cloned[action.payload.id] = action.payload.data
      state.servicesNew = cloned
      CalcDepartmentAndServiceKeyToName(state, action.payload.data)
    },
    setAllServicesNew: (state, action) => {
      const clone = _.clone(state.allServicesNew)
      const { id, data } = action.payload
      clone[id] = data
      state.allServicesNew = clone
    },
    setShowUnsubscribeModal: (state, action) => {
      state.showUnsubscribeModal = action.payload
    },
    setLanguageListerner: (state, action) => {
      state.isLanguageListenerAdded = action.payload
    },
    setHotelGuideLines: (state, action) => {
      state.hotelGuideLines = action.payload
    },
    setVersionNumber: (state, action) => {
      state.versionNumber = action.payload
    },
    setGuestSettingsList: (state, action) => {
      state.guestSettingsList = action.payload
    },
    setGuestSettingsLoading: (state, action) => {
      state.guestSettingsLoading = action.payload
    },
    setAllDepartmentsNew: (state, action) => {
      state.allDepartmentsNew = action.payload
    },
    setUrlLoader: (state, action) => {
      state.UrlLoader = action.payload
    },
    setGuestFromUrl: (state, action) => {
      state.guestFromUrl = action.payload
    },
    setHotelFromUrl: (state, action) => {
      state.hotelFromUrl = action.payload
    },
    setHotelDoorPresent: (state, action) => {
      state.hotelDoorPresent = action.payload
    },
  },
  extraReducers: {
    // for thunk events
  },
})

const persistConfig = {
  key: 'InPlass Guest',
  version: 1,
  storage,
  whitelist: [
    'appStatus',
    'logoutFrom',
    'subDomain',
    'referanceNumber',
    'hotelId',
    'guest',
    'cart',
    'selectedCuisineMenu',
    'selectedRestaurant',
    'unavailableItemsCountInCart',
    'duplicateRequest',
    'duplicateRequestServiceName',
    'hotelInfo',
    'appVersion',
    'loggedOutGuestId',
  ],
}

const { reducer } = slice
const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})

export const { actions } = slice
export const persistor = persistStore(store)
export default store

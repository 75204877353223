import { Collections } from '../config/Constants'
import { db } from '../config/firebase'
import { unsubscribeList, whiteList } from '../config/utils'
import { actions } from '../Store/index'

export const MaintenanceListerner = dispatch => {
  try {
    const collectionKey = `MaintenanceListerner${Collections.RELEASE}`
    if (unsubscribeList?.[collectionKey]) return
    whiteList.push(collectionKey)
    const unSub = db.collection(Collections.RELEASE).onSnapshot(snapshot => {
      let enableStickyBar = false,
        showMaintenancePage = false,
        fromDate = null,
        toDate = null,
        appVersion = '1'

      let data = snapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => b?.fromDate?.toDate() - a?.fromDate?.toDate())?.[0]

      if (+(data?.appVersion || '1') > +appVersion) {
        appVersion = data?.appVersion
      }

      if (data?.enableStickyBar) enableStickyBar = true
      if (data?.showMaintenancePage) showMaintenancePage = true
      if (enableStickyBar || showMaintenancePage) {
        fromDate = data.fromDate.toDate()
        toDate = data.toDate.toDate()
      }

      dispatch(
        actions.setProduction({
          enableStickyBar,
          showMaintenancePage,
          fromDate,
          toDate,
          appVersion,
        })
      )
    })
    unsubscribeList[collectionKey] = unSub
  } catch (error) {
    console.log({ error })
  }
}

/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { backArrow } from '../../../config/images'
import HotelNotFound from '../HotelNotFound'

const QRError = props => {
  return (
    <>
      <div class='header-wrp'>
        <div class='container-wrp'>
          <div class='row align-items-center'>
            <div class='col-auto'>
              <button
                id='BckBtn'
                class='backBtn my-3'
                onClick={e => {
                  e.preventDefault()
                  props.setScreen(2)
                }}
              >
                <img src={backArrow}></img>
              </button>
            </div>
          </div>
        </div>
      </div>

      <HotelNotFound message={props.msg}></HotelNotFound>
      {/*<div className='qrWrp'>
         <div className='qrData'>Error</div> <div className='qrBox'>
          <img
            className='img-fluid'
            src={qrImg}
            onClick={e => {
              e.preventDefault()
              // GetHotelData('grand-hyatt-vyp', dispatch)
              // history.push(`${routeLink.Hotel}/grand-hyatt-vyp`)
            }}
          ></img>
        </div>  <div className='qrBtn-wrp'>
          <button className='cmnBtn mt-3'>Lorem</button>
          <button className='outerlineBtn'> Scan Qr Code</button>
        </div> 
      </div>*/}
    </>
  )
}

export default QRError

import { db } from '../config/firebase'
import { Collections } from '../config/collection'
import { actions } from '../Store'
import { unsubscribeList, whiteList } from '../config/utils'

export const GetLanguageDictionary = (isLanguageListenerAdded, dispatch) => {
  try {
    const collectionKey = `GetLanguageDictionary${Collections.LANGUAGEDICTIONARY}`
    if (isLanguageListenerAdded || unsubscribeList?.[collectionKey]) return
    whiteList.push(collectionKey)
    dispatch(actions.setLanguageListerner(true))
    let unSub = db
      .collection(Collections.LANGUAGEDICTIONARY)
      .onSnapshot(querySnapshot => {
        let languageDictionary = []
        let flatLanguageData = {}
        querySnapshot.forEach(doc => {
          languageDictionary = [...languageDictionary, doc.data()]
          flatLanguageData = {
            ...flatLanguageData,
            [doc.data().id]: {
              ...flatLanguageData[doc.data().id],
              translation: {
                ...doc.data().data,
              },
            },
          }
        })
        const languageList = languageDictionary.map(item => {
          return { label: item.value, value: item.id }
        })
        dispatch(
          actions.setFetchLanguageDictionary({
            languageDictionary,
            languageList,
            flatLanguageData,
          })
        )
      })
    unsubscribeList[collectionKey] = unSub
  } catch (error) {
    console.log({ error })
  }
}

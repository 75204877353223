/* eslint-disable react-hooks/exhaustive-deps */
import QrScanner from 'qr-scanner'

import React, { useEffect, useRef, useState } from 'react'

let scanner

const inversionModeOptions = {
  original: 'original',
  invert: 'invert',
  both: 'both',
}

const cameraType = {
  environment: 'environment',
  user: 'user',
}

function QRCodeScanner(props) {
  const { setResponse = () => {}, setError = () => {}, heading = '' } = props
  const video = useRef(null)

  const [qrCodeData, setQrCodeData] = useState(null)
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    if (!qrCodeData && video.current) {
      scanner = new QrScanner(
        video.current,
        result => {
          setQrCodeData(result.data)
          setResult({ urldata: result.data, isSuccess: true })
        },
        {
          onDecodeError: _error => {
            setResult({ urldata: null, isSuccess: false })
          },
          highlightScanRegion: true,
          highlightCodeOutline: true,
        }
      )

      scanner.setInversionMode(inversionModeOptions.both)
      scanner.setCamera(cameraType.environment)
      scanner.start()
    }

    return () => {
      scanner.stop()
    }
  }, [qrCodeData])

  function browseQRCode(e) {
    if (e.target.files.length === 0) return

    const file = e.target?.files[0]

    QrScanner.scanImage(file, {
      returnDetailedScanResult: true,
    })
      .then(result => {
        setResult({ urldata: result.data })
      })
      .catch(e => {
        setResult({ urldata: null })
        setError('Invalid QR Code')
      })
  }

  function setResult({ urldata }) {
    setQrCodeData(urldata)
    if (urldata) {
      setResponse?.({ urldata })
      scanner.pause(true)
      return
    }
    setErrorMsg('Invalid QRCode')
  }

  return (
    <>
      <div className='qrWrp'>
        {heading}
        <div className='qrvideoBox'>
          <video id='qr-video' ref={video} className='img-fluid '></video>
          <label style={{ display: 'none' }}>{errorMsg}</label>
        </div>
        <div className='qrBtn-wrp'>
          <div className='image-upload'>
            <label htmlFor='file-input'>Upload</label>
            <input type='file' id='file-input' onChange={browseQRCode} />
          </div>
        </div>
      </div>
    </>
  )
}

export default QRCodeScanner

import { Collections } from '../config/Constants'
import { auth, db } from '../config/firebase'
import { Sort, unsubscribeList } from '../config/utils'
import { actions } from '../Store'

// Adding multiple listeners for getting departments > services > sub services
// -----------------START---------------------------
const AddServiceListener = ({ dispatch, docId, serviceId }) => {
  try {
    const collectionKey = `AddServiceListener${Collections.DEPARTMENTS}${docId}${serviceId}`
    if (!auth?.currentUser || unsubscribeList?.[collectionKey]) return

    let unSub = db.collection(Collections.DEPARTMENTS)

    if (serviceId) unSub = unSub.doc(serviceId).collection(Collections.SERVICES)

    unSub = unSub
      .doc(docId)
      .collection(Collections.SERVICES)
      .where('isDelete', '==', false)
      .onSnapshot(serviceSnapshot => {
        let services = []
        for (const service of serviceSnapshot.docs) {
          services.push({
            id: service.id,
            departmentId: docId,
            ...service.data(),
          })
        }
        services = Sort(services, 'index')

        AddServiceSubServiceListeners(services, dispatch, docId)

        dispatch(actions.setServicesNew({ id: docId, data: services }))
      })

    unsubscribeList[collectionKey] = unSub
  } catch (error) {
    console.log({ error })
  }
}

export const AddDepartmentListener = (
  dispatch,
  hotelId,
  departmentListenerAdded
) => {
  try {
    const collectionKey = `AddDepartmentListener${Collections.DEPARTMENTS}${hotelId}`

    if (
      !hotelId ||
      !auth?.currentUser ||
      departmentListenerAdded ||
      unsubscribeList?.[collectionKey]
    )
      return
    dispatch(actions.setDepartmentListenerAdded(true))

    const unSub = db
      .collection(Collections.DEPARTMENTS)
      .where('hotelId', '==', hotelId)
      .where('isDelete', '==', false)
      .where('default', '==', true)
      .onSnapshot(departmentSnapshot => {
        let departments = []
        for (const department of departmentSnapshot.docs) {
          departments.push({ id: department.id, ...department.data() })
        }
        departments = Sort(departments, 'index')

        // Add service listeners
        AddServiceSubServiceListeners(departments, dispatch)

        dispatch(actions.setDepartmentsNew(departments))
      })

    unsubscribeList[collectionKey] = unSub
  } catch (error) {
    console.log({ error })
  }
}

const AddServiceSubServiceListeners = (data, dispatch, serviceId) => {
  try {
    data.forEach(i => {
      if (i.services) AddServiceListener({ dispatch, docId: i.id, serviceId })
    })
  } catch (error) {
    console.log('❌ ', error)
  }
}

export const GetAllDepartmentListener = (dispatch, hotelId) => {
  try {
    const collectionKey = `GetAllDepartmentListener${Collections.DEPARTMENTS}${hotelId}`
    if (!hotelId || !auth?.currentUser || unsubscribeList?.[collectionKey])
      return

    const unSub = db
      .collection(Collections.DEPARTMENTS)
      .where('hotelId', '==', hotelId)
      .where('isDelete', '==', false)
      .where('active', '==', true)
      .onSnapshot(departmentSnapshot => {
        let departments = []
        for (const department of departmentSnapshot.docs) {
          departments.push({ id: department.id, ...department.data() })
        }
        departments = Sort(departments, 'index')
        dispatch(actions.setAllDepartmentsNew(departments))
      })

    unsubscribeList[collectionKey] = unSub
  } catch (error) {
    console.log({ error })
  }
}

export const GetAllServiceListener = ({
  dispatch,
  docId,
  serviceId,
  isSubService = false,
}) => {
  try {
    let collectionRef = db.collection(Collections.DEPARTMENTS)
    if (serviceId) {
      collectionRef = collectionRef
        .doc(serviceId)
        .collection(Collections.SERVICES)
    }

    let collectionKey = docId + `${serviceId ? '-' + serviceId : ''}`

    if (unsubscribeList[collectionKey]) return

    let sub = collectionRef
      .doc(docId)
      .collection(Collections.SERVICES)
      .where('isDelete', '==', false)
      .where('active', '==', true)
      .onSnapshot(serviceSnapshot => {
        let services = []
        for (const service of serviceSnapshot.docs) {
          services.push({ id: service.id, ...service.data(), isSubService })
        }
        services = Sort(services, 'name')
        dispatch(actions.setAllServicesNew({ id: docId, data: services }))
      })

    unsubscribeList[collectionKey] = sub
  } catch (error) {
    console.log({ error })
  }
}
// -----------------END---------------------------

/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { qrImg } from '../../../config/images'

const QRLanding = props => {
  return (
    <div className='qrWrp'>
      {props?.heading}
      <div className='qrBox'>
        <img style={{ width: '250px' }} className='img-fluid' src={qrImg}></img>
      </div>
      <div className='qrBtn-wrp'>
        <button
          className='cmnBtn mt-3'
          onClick={e => {
            e.preventDefault()
            props?.setScreen(2)
          }}
        >
          Scan
        </button>
      </div>
    </div>
  )
}

export default QRLanding

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import hideMenu from '../../../../constants/hideMenu'
import HamburgerMenu from '../../HamburgerMenu/HamburgerMenu'
import { GuestCheckinListener } from '../../../../services/guest'
import routeLink from '../../../../constants/routeLink'
import { Loader } from '../../Loader'
import ActionModal from '../../Modals/ActionModal'
import { UnsubscribeMessage } from '../../../../config/Constants'
import { actions } from '../../../../Store'
import { auth } from '../../../../config/firebase'
import { Logout } from '../../../../config/utils'
import GuestStatus from '../../../../constants/GuestStatus'
import { guestAppStatus } from '../../../../constants/general'
import ProductionDetailsToast from '../../ProductionDetailsToast/ProductionDetailsToast'
import {
  AddDepartmentListener,
  GetAllDepartmentListener,
} from '../../../../services/department'
import { AddServicesListener } from '../../../../services/service'

function PrivateLayout(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const [checkingCrossTabIssue, setCheckingCrossTabIssue] = useState(false)

  const {
    GetHotelDataFromIdListener,
    isGuestCheckinListener,
    hotelInfo,
    guest,
    showUnsubscribeModal,
    subDomain,
    departmentListenerAdded,
    servicesListenerAdded,
  } = useSelector(state => state)

  useEffect(() => {
    if (!checkingCrossTabIssue) {
      setCheckingCrossTabIssue(true)
      setInterval(() => {
        if (subDomain !== localStorage.getItem('hotel')) {
          localStorage.removeItem('guestId')
          localStorage.removeItem('apitoken')
          dispatch(actions.setAppStatus({ appStatus: guestAppStatus.Initial }))
          dispatch(actions.setLogout())
          window.location.reload()
        }
      }, 1000)
    }
  }, [checkingCrossTabIssue])

  useEffect(() => {
    function checkAuth() {
      if (hotelInfo?.isDelete || hotelInfo?.status === 'inactive') {
        history.push(routeLink.Hotel)
        return
      }
      const authtoken = localStorage.getItem('apitoken')
      if (authtoken) {
        GuestCheckinListener({
          ...props,
          dispatch,
          guestId: authtoken,
          isGuestCheckinListener,
          GetHotelDataFromIdListener,
          departmentListenerAdded,
          servicesListenerAdded,
        })
      } else {
        document.body.classList.remove('menuOpen')
        dispatch(
          actions.setAppStatus({
            logoutFrom: 'PrivateLayout',
            guestStatus: GuestStatus.CheckOut,
            appStatus: guestAppStatus.CheckOut,
          })
        )
        history.push(
          localStorage.getItem('hotel')
            ? `${routeLink.HotelWithKey}/${localStorage.getItem('hotel')}`
            : `${routeLink.HotelWithKey}`
        )
      }
    }
    checkAuth()
  }, [
    hotelInfo,
    localStorage.getItem('apitoken'),
    isGuestCheckinListener,
    GetHotelDataFromIdListener,
  ])

  useEffect(() => {
    let unsubscribeAuthChange = null
    const authChange = () => {
      unsubscribeAuthChange = auth.onAuthStateChanged(async _user => {
        if (!auth?.currentUser && guest?.status === GuestStatus.CheckIn) {
          dispatch(
            actions.setAppStatus({
              appStatus: guestAppStatus.CheckOut,
              logoutFrom: 'PrivateLayout',
              guestStatus: GuestStatus.CheckOut,
            })
          )
          Logout({ dispatch, guestId: guest?.id })
        }
      })
    }

    authChange()

    return () => {
      unsubscribeAuthChange?.()
    }
  }, [guest, dispatch])

  useEffect(() => {
    AddDepartmentListener(dispatch, hotelInfo?.id, departmentListenerAdded)
    AddServicesListener(hotelInfo?.id, dispatch, servicesListenerAdded)
    GetAllDepartmentListener(dispatch, hotelInfo?.id)
  }, [departmentListenerAdded, servicesListenerAdded])

  return useMemo(() => {
    return Object.keys(hotelInfo || {}).length &&
      Object.keys(guest || {}).length &&
      isGuestCheckinListener &&
      GetHotelDataFromIdListener ? (
      <>
        <ProductionDetailsToast />
        <div className='App'>
          <ActionModal
            messageText={UnsubscribeMessage}
            visible={showUnsubscribeModal}
            okButtonText='Confirm'
            cancelButtonText='Cancel'
            onCancelClick={() => {
              dispatch(actions.setShowUnsubscribeModal(false))
            }}
            onOkClick={() => {
              dispatch(actions.setShowUnsubscribeModal(false))
              const OneSignal = window.OneSignal
              OneSignal.setSubscription(false)
            }}
          />

          <div onClick={hideMenu} className='appin'>
            {props.children}
          </div>
          <HamburgerMenu></HamburgerMenu>
        </div>
      </>
    ) : (
      <Loader />
    )
  }, [
    hotelInfo,
    guest,
    isGuestCheckinListener,
    GetHotelDataFromIdListener,
    props.children,
  ])
}

export default PrivateLayout

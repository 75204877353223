import React from 'react'

const PoweredByInplass = () => (
  <div className='d-flex align-items-center justify-content-center h-100'>
    <h6 style={{ color: 'red' }}>Powered by</h6>
    <img
      style={{ marginLeft: '5px', marginTop: '1px' }}
      src="images/InPlass-Logo-Red.svg"
      alt=''
    ></img>
  </div>
)

export default PoweredByInplass

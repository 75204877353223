/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import routeLink from '../../../constants/routeLink'
import UserAvatar from 'react-user-avatar'
import {
  AddNotificationListener,
  DeletePlayerId,
  AddPlayerId,
} from '../../../services/notification'
import { useCustomI18NTranslatorHook } from '../../../utility/globalization'
import {
  Logout,
  onHandleClick,
  toTitleCase,
  unsubscribeFirebaseListeners,
} from '../../../config/utils'
import { auth } from '../../../config/firebase'
import { GetCurrentUserToken } from '../../../services/guest'
import { actions } from '../../../Store'
import GuestStatus from '../../../constants/GuestStatus'
import { guestAppStatus, loadProgress } from '../../../constants/general'
import {
  bell,
  getImage,
  hamburger_bellImg,
  LanguageIconImg,
  logoutImg,
  myhoteliconImg,
  overallfeedbackWhiteImg,
  powerdlogoImg,
  privacypolicyiconImg,
  profileiconImg,
  termscondtionsiconImg,
  doorImg,
} from '../../../config/images'
import { hotelGuideLinesListener } from '../../../services/hotels'
import getLatestVersion from '../../../services/versions'

const GetGuestName = guest =>
  guest?.name ? guest?.name + ' ' + guest?.surName : ''

const HamburgerMenu = () => {
  const {
    isNotificationListener,
    notifications,
    guest,
    hotelInfo,
    currentLanguage,
    hotelGuideLines,
    versionNumber,
    hotelDoorPresent,
  } = useSelector(state => state)

  const isDoorPresent = Boolean(localStorage.getItem('hotelDoorPresent'))
  const [notificationCount, setNotificationCount] = useState('')
  const [subscribedStatus, setSubscribedStatus] = useState('')
  const [operatingSystem, setOperatingSystem] = useState(false)
  const [initial, setInitial] = useState(true)
  const [translateTextI18N] = useCustomI18NTranslatorHook()
  const dispatch = useDispatch()
  let userImageText = GetGuestName(guest)

  useEffect(() => {
    getLatestVersion(dispatch)
  }, [dispatch])

  useEffect(() => {
    AddNotificationListener({
      guestId: guest?.id,
      isNotificationListener,
      dispatch,
    })
  }, [dispatch, isNotificationListener, guest?.id])

  useEffect(() => {
    let Lists = []
    Lists = notifications.filter(request => request.readStatus === false)
    setNotificationCount(Lists.length)
  }, [notifications])

  useEffect(() => {
    let platform = navigator?.userAgentData?.platform || 'unknown'
    const isIOS =
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].indexOf(platform) !== -1
    if (isIOS) {
      setOperatingSystem(true)
    }
    if (initial) {
      const OneSignal = window.OneSignal
      OneSignal.push(function () {
        OneSignal.isPushNotificationsEnabled().then(function (isEnabled) {
          if (isEnabled) {
            setSubscribedStatus('Unsubscribe Notification')
          } else {
            setSubscribedStatus('Subscribe Notification')
          }
        })
      })
    }
  }, [initial])

  const subscribe = async OneSignal => {
    OneSignal.getUserId().then(function (playerId) {
      OneSignal.sendTag('userType', 'guest')
      OneSignal.sendTag('hotelId', hotelInfo.id)
      OneSignal.setExternalUserId(guest?.id)
      if (guest?.id) {
        addplayerId(playerId)
      }
    })
  }

  useEffect(() => {
    const OneSignal = window.OneSignal || []
    if (typeof OneSignal.on === 'function') {
      OneSignal.push(async function () {
        OneSignal.on('subscriptionChange', function (isSubscribed) {
          if (isSubscribed) {
            setSubscribedStatus('Unsubscribe Notification')
            subscribe(OneSignal)
            setInitial(false)
          } else {
            setSubscribedStatus('Subscribe Notification')
            setInitial(false)
          }
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guest?.id])

  const addplayerId = async playerid => {
    AddPlayerId('guest', 'web', guest?.id, playerid, currentLanguage)
  }

  const changeStatus = async () => {
    const OneSignal = window.OneSignal

    OneSignal.isPushNotificationsEnabled().then(function (isEnabled) {
      if (isEnabled) {
        setInitial(false)
        dispatch(actions.setShowUnsubscribeModal(true))
      } else {
        setInitial(false)
        OneSignal.getUserId().then(function (playerId) {
          if (playerId == null) {
            OneSignal.showNativePrompt()
          } else {
            OneSignal.setSubscription(true)
          }
        })
      }
    })
  }

  const deletePlayerId = async token => {
    const OneSignal = window.OneSignal
    OneSignal.push(function () {
      OneSignal.getUserId().then(async function (playerId) {
        if (playerId != null) {
          DeletePlayerId('guest', 'web', guest?.id, playerId, token)
        }
        return true
      })
    })
  }

  useEffect(() => {
    if (hotelInfo?.id) {
      hotelGuideLinesListener(dispatch, hotelInfo?.id)
    }
  }, [dispatch, hotelInfo.id])

  return (
    <>
      <div className='sideMenu'>
        <div className='username-wrp'>
          <div className='userimg'>
            <UserAvatar size='36' name={userImageText} color='#FFF' />
          </div>
          <div>
            <h2>{toTitleCase(GetGuestName(guest))}</h2>
            {guest?.bookingReferance ?? (
              <h6>
                {translateTextI18N('Booking Ref')} - {guest.bookingReferance}
              </h6>
            )}
          </div>
          <a
            className='ml-auto d-flex'
            href='#'
            onClick={e =>
              onHandleClick({ e, url: routeLink.Notifications, dispatch })
            }
          >
            <img src={bell} />
            {notificationCount > 0 && (
              <span style={{ color: '#fff' }} className='notificationCount'>
                {notificationCount}
              </span>
            )}
          </a>
        </div>

        <ul className='list-unstyled'>
          <li>
            <a
              href='#'
              onClick={e =>
                onHandleClick({ e, url: routeLink.MyProfile, dispatch })
              }
            >
              <img src={profileiconImg}></img> {translateTextI18N('Profile')}
            </a>
          </li>
          <li>
            <a
              href='#'
              onClick={e =>
                onHandleClick({ e, url: routeLink.HotelInfo, dispatch })
              }
            >
              <img src={myhoteliconImg}></img> {translateTextI18N('My Hotel')}
            </a>
          </li>
          
          {/* <li>
            <a
              href='#'
              onClick={e =>
                onHandleClick({ e, url: routeLink.Chat, dispatch })
              }
            >
              <img src={myhoteliconImg}></img> {translateTextI18N('Chat With IVA')}
            </a>
          </li> */}
          <li>
            <a
              href='#'
              onClick={e =>
                onHandleClick({
                  e,
                  url: routeLink.ChangeLanguageScreen,
                  dispatch,
                })
              }
            >
              <img src={LanguageIconImg}></img>
              {translateTextI18N('Language')}
            </a>
          </li>
          {!operatingSystem && (
            <li>
              <a href='#' onClick={changeStatus}>
                <img src={hamburger_bellImg} />
                {translateTextI18N(subscribedStatus)}
              </a>
            </li>
          )}
          <li>
            <a
              href='#'
              onClick={e =>
                onHandleClick({
                  e,
                  url: routeLink.OverallFeedback,
                  dispatch,
                })
              }
            >
              <img src={overallfeedbackWhiteImg}></img>
              {translateTextI18N('Feedback')}
            </a>
          </li>

          {hotelGuideLines.loadingStatus === loadProgress.LOADED &&
            hotelGuideLines.data.length > 0 && (
              <li>
                <a
                  href='#'
                  onClick={e =>
                    onHandleClick({
                      e,
                      url: routeLink.HotelGuidelines,
                      dispatch,
                    })
                  }
                >
                  <img src={getImage('book.png')}></img>
                  {translateTextI18N('Hotel Guidelines')}
                </a>
              </li>
            )}
          <li>
            <a
              href='#'
              onClick={e =>
                onHandleClick({
                  e,
                  url: routeLink.TermsandConditions,
                  dispatch,
                })
              }
            >
              <img src={termscondtionsiconImg}></img>
              {translateTextI18N('Terms & Conditions')}
            </a>
          </li>
          <li>
            <a
              href='#'
              onClick={e =>
                onHandleClick({ e, url: routeLink.PrivacyPolicy, dispatch })
              }
            >
              <img src={privacypolicyiconImg}></img>
              {translateTextI18N('Privacy Policy')}
            </a>
          </li>
          {(hotelDoorPresent || isDoorPresent) &&
          hotelInfo.id === 'd6zCaMxnPW77OAKjIcf3' ? (
            <li>
              <a
                href='#'
                onClick={e =>
                  onHandleClick({ e, url: routeLink.Door, dispatch })
                }
              >
                <img src={doorImg}></img>
                {translateTextI18N('My Door')}
              </a>
            </li>
          ) : (
            <></>
          )}

          <li>
            <a
              href='#'
              onClick={async e => {
                const token = await GetCurrentUserToken()
                let status = deletePlayerId(token)
                if (status) {
                  e.preventDefault()
                  await auth
                    .signOut()
                    .then(() => {
                      unsubscribeFirebaseListeners()
                      dispatch(
                        actions.setAppStatus({
                          logoutFrom: 'PrivateLayout',
                          guestStatus: GuestStatus.CheckOut,
                          appStatus: guestAppStatus.CheckOut,
                        })
                      )

                      Logout({ dispatch, guestId: guest?.id })
                    })
                    .catch(err => console.log(err))
                }
              }}
            >
              <img src={logoutImg} /> {translateTextI18N('Logout')}
            </a>
          </li>
        </ul>
        <div className='menufooter'>
          <div className='d-flex flex-column'>
            {/* <img src={powerdtextImg}></img> */}
            <h6 style={{ color: 'white', fontSize: '12px' }}>
              GUEST by iNPLASS Ver {versionNumber}
            </h6>
            <div className='d-flex align-items-center'>
              <h6 style={{ color: 'white' }}>{'Powered by'}</h6>
              <img
                style={{ marginLeft: '5px', marginTop: '1px' }}
                src={powerdlogoImg}
              ></img>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HamburgerMenu

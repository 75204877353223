import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { message } from 'antd'
import { actions } from '../../../Store'
import routeLink from '../../../constants/routeLink'
import { history } from '../../../history'
import GuestStatus from '../../../constants/GuestStatus'
import ActionModal from '../../Common/Modals/ActionModal'
import { CreateNewGuest, GetGuest } from '../../../services/guest'

const isPseudoBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  const pseudoBrowserPatterns = /FBAN|FBAV|Instagram|Twitter|Line|WebView|wv/i
  const wifiPseudoBrowserPatterns =
    /CaptiveNetwork|captiveportal|CaptiveWebSheet|portal|WISPr|wifi/i

  return (
    pseudoBrowserPatterns.test(userAgent) ||
    wifiPseudoBrowserPatterns.test(userAgent) ||
    navigator.standalone ||
    window.matchMedia('(display-mode: standalone)').matches
  )
}

const BrowserCheck = ({ children }) => {
  const [isPseudoBrowserEnv, setIsPseudoBrowserEnv] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [currentUrl, setCurrentUrl] = useState('')
  const [loader, setLoader] = useState(false) // Ensure it's used correctly if required
  const dispatch = useDispatch()

  const {
    isGuestCheckinListener,
    currentLanguage,
    GetHotelDataFromIdListener,
    hotelInfo
  } = useSelector(state => state)

  const enablePseudoBrowserCheckSetting =
  hotelInfo?.enablePseudoBrowserCheckSetting || false;
  const urlParams = new URLSearchParams(window.location.search)
  const guestFromUrl = urlParams.get('gid')
  const hotelFromUrl = urlParams.get('hid')
  const hotel = urlParams.get('hotelHid')

  const handleCheckInClick = useCallback(async () => {
    dispatch(actions.setUrlLoader(true))
    try {
      localStorage.setItem('hotel', hotel)
      if (guestFromUrl && hotelFromUrl) {
        const guestDetails = await GetGuest(guestFromUrl)
        const { name, surName, bookingReferance, roomNumber } = guestDetails
        const guestInfo = {
          status: GuestStatus.CheckIn,
          captcha: null,
          language: currentLanguage,
          source: 'wifi',
          ...(name && { name }),
          ...(surName && { surName }),
          ...(bookingReferance && { bookingReferance }),
          ...(roomNumber && { roomNumber }),
        }

        const { success } = await CreateNewGuest({
          guest: guestInfo,
          hotelId: hotelFromUrl,
          dispatch,
          currentLanguage,
          isGuestCheckinListener,
          GetHotelDataFromIdListener,
        })

        history.replace(success ? routeLink.Home : '/')
      }
    } catch (error) {
      console.error('Error in handleCheckInClick:', error.message || error)
    } finally {
      setLoader(false)
      dispatch(actions.setUrlLoader(false))
    }
  }, [
    dispatch,
    hotel,
    guestFromUrl,
    hotelFromUrl,
    currentLanguage,
    isGuestCheckinListener,
    GetHotelDataFromIdListener,
  ])

  useEffect(() => {
    const apiToken = localStorage.getItem('apitoken')

    const checkSession = async () => {
      if (guestFromUrl) {
        await handleCheckInClick()
      } else if (enablePseudoBrowserCheckSetting && isPseudoBrowser() && apiToken) {
        setIsPseudoBrowserEnv(true)
        setIsModalVisible(true)
        generateUrlWithSessionKey()
      }
    }

    checkSession()
  }, [
    handleCheckInClick,
    guestFromUrl,
    isPseudoBrowser,
    enablePseudoBrowserCheckSetting,
  ])

  const generateUrlWithSessionKey = useCallback(() => {
    const storedSessionKey = localStorage.getItem('apitoken')
    const hotelName = localStorage.getItem('hotel')
    const guestDataString = localStorage.getItem('persist:InPlass Guest')
    const guestData = guestDataString
      ? JSON.parse(JSON.parse(guestDataString).guest)
      : null

    if (!guestData) return

    const hotelId = guestData.hotelId
    const urlWithSessionKey = `${window.location.href}?gid=${storedSessionKey}&hid=${hotelId}&hotelHid=${hotelName}`
    setCurrentUrl(urlWithSessionKey)
  }, [])

  const handleCopyClick = () => {
    navigator.clipboard.writeText(currentUrl).then(() => {
      setIsModalVisible(false)
      message.success('Link copied to clipboard!')
    })
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      {/* {isModalVisible && ( */}
        <ActionModal
          messageText='For better experience, please open this site in your browser'
          visible={isModalVisible}
          okButtonText='Copy Link'
          cancelButtonText='Cancel'
          onlyOkButton={false}
          onCancelClick={handleCancel}
          onOkClick={handleCopyClick}
          showButton={true}
        />
      {/* )} */}
      {children}
    </>
  )
}

export default BrowserCheck

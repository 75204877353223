/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { networkStatus } from '../constants/general'
import { actions } from '../Store'

export const OnlineStatusContext = React.createContext(true)

const PING_RESOURCE = '/manifest.json'
const TIMEOUT_TIME_MS = 1000
const onlinePollingInterval = 1000 * 2

const timeout = (time, promise) => {
  return new Promise(function (resolve, reject) {
    setTimeout(() => {
      reject(new Error('Request timed out.'))
    }, time)
    promise.then(resolve, reject)
  })
}

const checkOnlineStatus = async () => {
  const controller = new AbortController()
  const { signal } = controller

  // If the browser has no network connection return offline
  if (!navigator.onLine) return networkStatus.FAILED

  //
  try {
    await timeout(
      TIMEOUT_TIME_MS,
      fetch(PING_RESOURCE, { method: 'GET', signal })
    )
    return networkStatus.SUCCESS
  } catch (error) {
    // Error Log
    console.error(error)

    // This can be because of request timed out
    // so we abort the request for any case
    controller.abort()
  }
  return false
}

export const OnlineStatusProvider = ({ children }) => {
  const urlParams = new URLSearchParams(window.location.search)
  const guestIdParam = urlParams.get('gid')
  const hotelIdParam = urlParams.get('hid')

  const online = useSelector(state => state.online)

  const dispatch = useDispatch()

  const checkStatus = async () => {
    const onlineStatus = await checkOnlineStatus()
    setOnlineStatus(onlineStatus)
  }

  useEffect(() => {
    window.addEventListener('offline', () => {
      setOnlineStatus(networkStatus.FAILED)
    })

    const netInterval = setInterval(() => {
      checkStatus()
    }, onlinePollingInterval)

    return () => {
      window.removeEventListener('offline', () => {
        setOnlineStatus(networkStatus.FAILED)
      })

      clearInterval(netInterval)
    }
  }, [])

  const setOnlineStatus = status => {
    dispatch(actions.setOnline(status))
  }

  useEffect(async () => {
    if (guestIdParam && hotelIdParam) {
      dispatch(actions.setUrlLoader(true))
      dispatch(actions.setGuestFromUrl(guestIdParam))
      dispatch(actions.setHotelFromUrl(hotelIdParam))
    }
  }, [guestIdParam, hotelIdParam])

  return (
    <OnlineStatusContext.Provider value={{ online }}>
      {children}
    </OnlineStatusContext.Provider>
  )
}

export const useOnlineStatus = () => {
  return useContext(OnlineStatusContext)
}

import { Cookies } from 'react-cookie'
export const cookies = new Cookies()

export const accessibility = {
  HOTEL: 'HOTEL',
  GUEST: 'GUEST',
  PUBLIC: 'PUBLIC',
  CONFIRMATION_CHECKPOINT: 'CONFIRMATION_CHECKPOINT',
  DUPLICATE_ROUTE: 'DUPLICATE_ROUTE',
}

export const loadProgress = { TOLOAD: 'toLoad', LOADING: 'loading', LOADED: 'loaded' }

export const networkStatus = {
  TOLOAD: 'toLoad',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILED: 'failed',
}

export const searchingHotelMessages = {
  LOADING: 'Loading...',
  NOT_AVAILABLE: 'Hotel is not available.',
  INVALID: 'The requested URL is currently unavailable.',
  SUCCESS: 'Success',
  PUBLIC: 'public',
}

export const authtoken = window.localStorage.getItem('apitoken')
export const guestId = window.localStorage.getItem('guestId')
export const hotelname = window.localStorage.getItem('hotel')
export const language = window.localStorage.getItem('language')
export const tmplanguage = window.localStorage.getItem('tmplanguage')

export const langClassName = {
  ml: 'malayalamLang',
  ar: 'arabicLang',
  ur: 'urduLang',
}

export const langList = {
  it: 'it',
  en: 'en',
  ar: 'ar',
  ru: 'ru',
  zh: 'zh',
  fr: 'fr',
  es: 'es',
  de: 'de',
  tl: 'tl',
  ur: 'ur',
  hi: 'hi',
  ml: 'ml',
}

export const guestAppStatus = {
  Initial: 'Initial',
  CheckIn: 'CheckIn',
  CheckOut: 'CheckOut',
}

import { useLoading, ThreeDots } from '@agney/react-loading'

const useLoader = () => {
  const { indicatorEl } = useLoading({
    loading: true,
    indicator: <ThreeDots width='30' />,
  })
  return indicatorEl
}

export default useLoader

import { Collections } from '../config/collection'
import { auth, db, timestamp } from '../config/firebase'
import { loadProgress, searchingHotelMessages } from '../constants/general'
import routeLink from '../constants/routeLink'
import URL from '../constants/apiUrls'
import { history } from '../history'
import { actions } from '../Store'
import { creationData, updatationData } from './common'
import { GetHotelDataFromId } from './hotels'
import Axios from '../utility/axiosHelper'
import { AddPlayerId, GuestRequest } from './notification'
import { CheckInStatus } from '../config/Constants'
import { Sort, unsubscribeList } from '../config/utils'
import DepartmentAndServiceKeys from '../config/departmentAndServicekeys'

async function autologin({
  data,
  dispatch,
  isGuestCheckinListener,
  currentLanguage,
  GetHotelDataFromIdListener,
  hotelId,
}) {
  try {
    if (!data || !data.token) {
      return {
        success: false,
        message: 'Something went wrong. Please try again',
      }
    }
    let userCred = await auth.signInWithCustomToken(data.token).catch(() => {
      // This is intentional
    })
    if (!userCred) {
      return {
        success: false,
        message: 'Something went wrong. Please try again',
      }
    }

    let OneSignal = window.OneSignal
    OneSignal.push(function () {
      OneSignal.getUserId().then(function (playerId) {
        if (playerId != null) {
          OneSignal.sendTag('userType', 'guest')
          OneSignal.sendTag('hotelId', hotelId)
          OneSignal.setExternalUserId(data.guest?.id)
          AddPlayerId('guest', 'web', data.guest?.id, playerId, currentLanguage)
        }
      })
    })

    if (data.guest.status !== CheckInStatus) {
      let template_variables = {
        '%name%': `${data.guest.name} ${data.guest.surName}`,
        '%roomNumber%': data.guest.roomNumber,
      }
      let dataList = {
        notification_type: 'CHECKIN',
        template_variables: template_variables,
        guest_id: data.guest?.id,
        hotel_id: hotelId,
        department_type: DepartmentAndServiceKeys.frontDesk.key,
        requestType: 'Urgent',
        currentLanguage: currentLanguage,
        referenceId: data.guest?.id,
        entityType: 'GUEST',
      }
      GuestRequest(dataList)
    }

    localStorage.setItem('guestId', data.guest.id)

    GuestCheckinListener({
      dispatch,
      guestId: data.guest.id,
      isGuestCheckinListener,
      GetHotelDataFromIdListener,
    })
    dispatch(actions.setSubDomain(localStorage.getItem('hotel')))
    dispatch(actions.setGuest(data.guest))
    dispatch(actions.setUserName(`${data.guest.name} ${data.guest.surName}`))
    return { success: true, message: 'Guest added successfully', data }
  } catch (error) {
    console.log(error?.message)
    return { success: false, message: error?.message }
  }
}

export const CreateNewGuest = async ({
  guest,
  hotelId,
  dispatch,
  currentLanguage,
  isGuestCheckinListener,
  GetHotelDataFromIdListener,
}) => {
  try {
    const { data } = await Axios.post(
      URL.GUEST_LOGIN,
      { hotelId, ...guest },
      { 'Accept-Language': currentLanguage }
    )

    return await autologin({
      data,
      dispatch,
      hotelId,
      currentLanguage,
      isGuestCheckinListener,
      GetHotelDataFromIdListener,
    })
  } catch (error) {
    console.log(error?.message)
    return { success: false, message: error?.message }
  }
}

export const GetCurrentUserToken = () =>
  Promise.resolve(auth.currentUser?.getIdToken())

export const GetCurrentUser = () => auth.currentUser

export const UpdateGuestData = async (
  guest,
  countryName,
  guestId,
  dispatch
) => {
  try {
    await db
      .collection(Collections.GUEST)
      .doc(guestId)
      .update({ ...guest, countryName: countryName, ...updatationData() })
    const guestDoc = await db.collection(Collections.GUEST).doc(guestId).get()
    dispatch(actions.setGuest({ id: guestDoc.id, ...guestDoc.data() }))
    return { success: true, message: 'Profile updated successfully' }
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
    return { success: false, message: error?.message }
  }
}

export const GuestCheckinListener = ({
  dispatch,
  guestId = '',
  isGuestCheckinListener,
  GetHotelDataFromIdListener,
}) => {
  try {
    const collectionKey = `GuestCheckinListener${Collections.GUEST}${guestId}`

    if (
      isGuestCheckinListener ||
      !auth?.currentUser ||
      !guestId ||
      unsubscribeList?.[collectionKey]
    )
      return

    const unSub = db
      .collection(Collections.GUEST)
      .doc(guestId)
      .onSnapshot(async guestRef => {
        dispatch(actions.setGuestCheckinListener(true))
        const guest = { id: guestId, ...guestRef.data() }

        if (!guest) {
          localStorage.removeItem('apitoken')
          localStorage.removeItem('guestId')
          dispatch(
            actions.setSearchingHotel({
              searchingHotel: loadProgress.LOADED,
              searchingHotelMessage: searchingHotelMessages.INVALID,
            })
          )

          history.push(routeLink.Hotel)
          return
        }

        if ('status' in guest) {
          if (guest?.hotelId) {
            GetHotelDataFromId(
              guest.hotelId,
              dispatch,
              GetHotelDataFromIdListener
            )
          }
          if (auth.currentUser) {
            dispatch(actions.setGuest(guest))
          }
        }
      })

    unsubscribeList[collectionKey] = unSub
  } catch (error) {
    console.log({ error })
  }
}

export const GetGuestData = async (
  guestId,
  dispatch,
  GetHotelDataFromIdListener,
  isGuestCheckinListener
) => {
  let dataValue = []
  try {
    if (isGuestCheckinListener) return
    const guestDoc = await db.collection(Collections.GUEST).doc(guestId).get()
    if (guestDoc) {
      dataValue = guestDoc.data()

      if (!dataValue) {
        localStorage.removeItem('apitoken')
        localStorage.removeItem('guestId')
        dispatch(
          actions.setSearchingHotel({
            searchingHotel: loadProgress.LOADED,
            searchingHotelMessage: searchingHotelMessages.INVALID,
          })
        )
        history.push(routeLink.Hotel)
        return
      }
      GuestCheckinListener({
        dispatch,
        guestId,
        isGuestCheckinListener,
        GetHotelDataFromIdListener,
      })
    }
  } catch (error) {
    console.log({ error })
  }
  return dataValue
}

export const GetGuest = async guestId => {
  let data = {}
  try {
    const guestSnapshot = await db
      .collection(Collections.GUEST)
      .doc(guestId)
      .get()
    if (guestSnapshot) {
      data = guestSnapshot.data()
    }
  } catch (error) {
    console.log({ error })
  }
  return data
}

export const UpdateGuestLanguage = async (language, guestId) => {
  try {
    await db
      .collection(Collections.GUEST)
      .doc(guestId)
      .update({ language, ...updatationData() })
    return { success: true, message: 'Language updated successfully' }
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
    return { success: false, message: error?.message }
  }
}

export const WIFI_AUTH = async ({
  currentLanguage,
  subdomain,
  guestAuthData,
  dispatch,
  isGuestCheckinListener,
  GetHotelDataFromIdListener,
  currentHotelKey,
}) => {
  try {
    const autoLoginData = { subdomain, encryptedData: guestAuthData }
    const options = { headers: { 'Accept-Language': 'en' } }
    const { WIFI_AUTH_LOGIN } = URL

    const response = await Axios.post(WIFI_AUTH_LOGIN, autoLoginData, options)

    if (response.statusCode === 200) {
      const { data } = response

      if (data) {
        const { success } = await autologin({
          data,
          dispatch,
          isGuestCheckinListener,
          currentLanguage,
          GetHotelDataFromIdListener,
          hotelId: data.guest.hotelId,
        })

        if (success) {
          localStorage.removeItem('guestId')
          localStorage.setItem('apitoken', data.guest.id)
          localStorage.setItem('hotel', currentHotelKey)
          return { success: true, loadingStatus: loadProgress.LOADED }
        }
      }
    }
  } catch (error) {
    return { success: false, loadingStatus: loadProgress.LOADED }
  }
}

export const saveOverAllFeedback = async ({
  hotelId,
  guestId,
  feedBackData,
}) => {
  try {
    const guestInfo = await GetGuest(guestId)
    await db
      .collection(Collections.HOTELS)
      .doc(hotelId)
      .collection(Collections.HOTELFEEDBACKS)
      .doc(guestId)
      .set({
        hotelId,
        guestId,
        feedBackDateTime: timestamp,
        ...feedBackData,
        ...creationData(),
      })

    const { calcAvgRating, feedback } = feedBackData
    const { roomNumber } = guestInfo

    const obj = {
      notificationType: 'GUEST_FEEDBACK',
      templateVariables: {
        '%roomNumber%': roomNumber,
        '%comment%': feedback || 'No Comment',
        '%rating%': calcAvgRating,
      },
      guestId,
      hotelId,
    }

    await Axios.post(URL.FEEDBACK_NOTIFICATION, { ...obj })
    return true
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
    return false
  }
}

export const fetchGuestHotelFeedbackListener = (dispatch, hotelId, guestId) => {
  let collectionKey = `fetchGuestHotelFeedbackListener`
  if (!hotelId || unsubscribeList[collectionKey]) return
  const setFunc = actions.setCurGuestPreviousFeedback

  try {
    dispatch(setFunc({ loadingStatus: loadProgress.LOADING }))
    const unSub = db
      .collection(Collections.HOTELS)
      .doc(hotelId)
      .collection(Collections.HOTELFEEDBACKS)
      .doc(guestId)
      .onSnapshot(doc => {
        let guestFeedBack = doc.data()

        if (typeof guestFeedBack === 'object') {
          if (guestFeedBack?.questionnaire) {
            guestFeedBack.questionnaire = guestFeedBack.questionnaire?.map(
              d => ({
                ...d,
                id: d.questionId,
              })
            )
          }
        } else {
          guestFeedBack = {}
        }

        dispatch(
          setFunc({
            data: guestFeedBack,
            loadingStatus: loadProgress.LOADED,
          })
        )
      })

    unsubscribeList[collectionKey] = unSub
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
    dispatch(setFunc({ loadingStatus: loadProgress.LOADED }))
    return { success: false, message: error?.message }
  }
}

export const GuestOnboardSettingsListener = async (hotelId, dispatch) => {
  try {
    let collectionKey = `GuestOnboardSettingsListener_${Collections.ADMINSETTINGS}_${Collections.ADMIN_GUESTSETTINGS}`

    if (hotelId && !unsubscribeList[collectionKey]) {
      dispatch(actions.setGuestSettingsLoading(true))
      let unsub = db
        .collection(Collections.ADMINSETTINGS)
        .doc(hotelId)
        .collection(Collections.ADMIN_GUESTSETTINGS)
        .onSnapshot(settingsSanapshot => {
          let guestSettings = []
          if (settingsSanapshot.size > 0) {
            settingsSanapshot.forEach(doc => {
              guestSettings.push({ id: doc.id, ...doc.data() })
            })
            guestSettings = Sort(guestSettings, 'order')
            dispatch(actions.setGuestSettingsList(guestSettings))
          }
        })
      dispatch(actions.setGuestSettingsLoading(false))
      unsubscribeList[collectionKey] = unsub
    }
  } catch (error) {
    console.log({ error })
  }
}

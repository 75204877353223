import { useRef, useEffect, useState } from 'react'
import { getMobileOS } from '../config/utils'

function useDetectMobile() {
  const [deviceTypeId, setDeviceTypeId] = useState('Other')
  const [loadDetectMobile, setLoadDetectMobile] = useState(false)
  let loadingScreenRef = useRef(null)

  useEffect(() => {
    let interval = setInterval(() => {
      let dT = getMobileOS()
      if (dT !== deviceTypeId) {
        setDeviceTypeId(dT)
        setLoadDetectMobile(true)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [deviceTypeId])

  useEffect(() => {
    if (loadDetectMobile && !loadingScreenRef.current) {
      loadingScreenRef.current = setTimeout(() => {
        setLoadDetectMobile(false)
      }, 1000)
    } else {
      loadingScreenRef.current = null
    }

    return () => {
      clearTimeout(loadingScreenRef.current)
      loadingScreenRef.current = null
    }
  }, [loadDetectMobile])

  return [deviceTypeId, loadDetectMobile]
}

export default useDetectMobile

/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, Suspense } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import routeLink from '../constants/routeLink'
import PrivateLayout from '../components/Common/Layout/Private'
import { AppContext } from '../config/context'
import { actions } from '../Store'
import { loadProgress, networkStatus, searchingHotelMessages } from '../constants/general'
import GuestStatus from '../constants/GuestStatus'
import { Loader } from '../components/Common/Loader'
import OfflinePage from '../components/Pages/OfflinePage'

export function AdminComponent({ render: Component, ...resProps }) {
  return (
    <Suspense fallback={<Loader />}>
      <PrivateLayout {...resProps}>
        <Component {...resProps} />
      </PrivateLayout>
    </Suspense>
  )
}
const PrivateDuplicateRoute = rest => {
  const dispatch = useDispatch()

  return (
    <Route
      {...rest}
      render={props => {
        return (
          <AppContext.Consumer>
            {context => {
              if (context.appState.online === networkStatus.FAILED) {
                return <OfflinePage />
              }

              if (
                localStorage.getItem('apitoken') &&
                !localStorage.getItem('guestId') &&
                localStorage.getItem('hotel') &&
                context.appState.guest.status === GuestStatus.CheckIn
              ) {
                return <AdminComponent {...props} {...rest} />
              } else {
                localStorage.removeItem('guestId')
                localStorage.removeItem('apitoken')
                dispatch(actions.setLogout())
                dispatch(
                  actions.setSearchingHotel({
                    searchingHotel: loadProgress.LOADED,
                    searchingHotelMessage: searchingHotelMessages.INVALID,
                  })
                )
                return (
                  <Redirect
                    to={{
                      state: { from: props.location },
                      pathname: localStorage.getItem('hotel')
                        ? `${routeLink.HotelWithKey}/${localStorage.getItem('hotel')}`
                        : `${routeLink.Hotel}`,
                    }}
                  />
                )
              }
            }}
          </AppContext.Consumer>
        )
      }}
    />
  )
}

export default memo(PrivateDuplicateRoute)

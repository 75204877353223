/* eslint-disable array-callback-return */
import { useTranslation } from 'react-i18next'

export const useCustomI18NTranslatorHook = () => {
  const { t } = useTranslation()
  function translateTextI18N(textString, options) {
    if (typeof textString === 'string' && textString !== '') {
      let curText = textString.trim()
      if (options) {
        if (Object.keys(options)?.length > 0) {
          return t(curText, options)
        }
      }
      return t(curText)
    }
    return textString
  }
  return [translateTextI18N]
}



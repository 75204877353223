import React, { memo } from 'react'
import { Loader } from '../components/Common/Loader'
import LazyLoader from './LazyLoader'

function LoadRoute(props) {
  return (
    <LazyLoader
      componentPath={props.routeComponentPath}
      componentParent={props.routeComponentParent}
      fallback={<Loader />}
      {...props}
    />
  )
}

export default memo(LoadRoute)

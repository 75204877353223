import React from 'react'
import ReactDOM from 'react-dom'
import 'lightgallery.js/dist/css/lightgallery.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './scss/style.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { removeIFRAMETag } from './config/utils'

if (
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_ENV !== 'prod'
) {
  const aScript = document.createElement('script')
  aScript.type = 'text/javascript'
  aScript.src = process.env.PUBLIC_URL + '/newrelic-ui.js'

  document.head.appendChild(aScript)
  aScript.onload = function () {
    console.log('NewRelic Activated')
  }
}

if (process.env.REACT_APP_SENTRY) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    integrations: [new Integrations.BrowserTracing()],

    attachStacktrace: true,
    tracesSampleRate: 1.0,
  })
  console.log('Sentry Activated')
}

window.addEventListener('message', () => {
  setInterval(() => {
    removeIFRAMETag()
  }, 1000)
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
reportWebVitals()

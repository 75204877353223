import { Collections } from '../config/collection'
import { db } from '../config/firebase'
import { actions } from '../Store'

const getLatestVersion = async dispatch => {
  try {
    const latestVersion = await db
      .collection(Collections.VERSIONS)
      .orderBy('versionDate', 'desc')
      .limit(1)
      .get()
    if (!latestVersion.empty) {
      let newVersion = GetDataFromSnapshot(latestVersion)
      dispatch(actions.setVersionNumber(newVersion[0].versionNumber))
    } else {
      console.log('No versions found')
    }
  } catch (error) {
    console.log('Error retrieving versions:', error)
  }
}

export default getLatestVersion

export const GetDataFromSnapshot = snapshot => {
  const data = []
  for (const doc of snapshot.docs) {
    data.push({ id: doc.id, ...doc.data() })
  }
  return data
}

import React, { memo, Suspense } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import routeLink from '../constants/routeLink'
import PrivateLayout from '../components/Common/Layout/Private'
import { AppContext } from '../config/context'
import GuestStatus from '../constants/GuestStatus'
import { Loader } from '../components/Common/Loader'
import { guestAppStatus, networkStatus } from '../constants/general'
import OfflinePage from '../components/Pages/OfflinePage'
import { CheckoutGuest } from '../config/utils'

function redirectPage({ appStatus, logoutFrom, guest }) {
  if (appStatus === guestAppStatus.CheckOut) {
    document?.body?.classList?.remove('menuOpen')
    document?.getElementById('BckBtn')?.classList?.remove('newClass')
    localStorage.removeItem('apitoken')
    localStorage.removeItem('guestId')

    if (!('status' in guest) && logoutFrom) {
      return <Redirect to={routeLink.IntermediateScreen} />
    }

    if (logoutFrom) {
      return <Redirect to={routeLink.IntermediateScreen} />
    }
  }

  if (!('status' in guest) && logoutFrom !== 'PrivateLayout') {
    document?.body?.classList?.remove('menuOpen')
    document?.getElementById('BckBtn')?.classList?.remove('newClass')
    return <Redirect to={routeLink.Hotel} />
  }
}

const PrivateRoute = ({ render: Component, ...rest }) => {
  const dispatch = useDispatch()
  function Admin(resProps) {
    const { duplicateRequest } = useSelector(state => state)
    if (duplicateRequest) {
      return <Redirect to={routeLink.SuccessScreen} />
    }

    return (
      <Suspense fallback={<Loader />}>
        <PrivateLayout {...resProps}>
          <Component {...resProps} />
        </PrivateLayout>
      </Suspense>
    )
  }

  return (
    <Route
      {...rest}
      render={props => {
        return (
          <AppContext.Consumer>
            {context => {
              const { guest, online, appStatus, logoutFrom } = context.appState
              if (guest?.status === GuestStatus.CheckOut) {
                localStorage.removeItem('apitoken')
                localStorage.setItem('guestId', guest?.id)
              }

              if (guest?.status === GuestStatus.CheckIn) {
                localStorage.removeItem('guestId')
                localStorage.setItem('apitoken', guest?.id)
              }

              let ls_apitoken = localStorage.getItem('apitoken')
              let ls_hotel = localStorage.getItem('hotel')
              let ls_guestId = localStorage.getItem('guestId')

              if (ls_apitoken && ls_hotel && online === networkStatus.FAILED) {
                return <OfflinePage />
              }

              if (
                ls_apitoken &&
                ls_hotel &&
                !ls_guestId &&
                'status' in guest &&
                guest?.status === GuestStatus.CheckIn &&
                logoutFrom !== 'PrivateLayout'
              ) {
                return <Admin {...props} {...rest} {...context.appState} />
              }

              if (
                ls_hotel &&
                ls_guestId &&
                !ls_apitoken &&
                'status' in guest &&
                guest?.status === GuestStatus.CheckOut
              ) {
                CheckoutGuest(dispatch, ls_guestId)
              }

              return redirectPage({ appStatus, logoutFrom, guest })
            }}
          </AppContext.Consumer>
        )
      }}
    />
  )
}

export default memo(PrivateRoute)

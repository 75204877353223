/* eslint-disable no-empty-pattern */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentHotelKey } from '../../../config/utils'
import { searchingHotelMessages } from '../../../constants/general'
import routeLink from '../../../constants/routeLink'
import { history } from '../../../history'
import { GetHotelData, GetHotelDataByKey } from '../../../services/hotels'
import { actions } from '../../../Store'
import { Loader } from '../../Common/Loader'
import QRCodeScanner from '../../Common/QRCode/QRCodeScanner'
import QRError from './QrError'
import QRLanding from './QRLanding'

const QRScreen = () => {
  const [loading, setLoading] = useState(false)
  const [screen, setScreen] = useState(1)
  const [url, setUrl] = useState('')
  const [resStatus, setResStatus] = useState({
    msg: '',
    success: null,
    data: null,
  })
  const dispatch = useDispatch()

  async function setResponse({ urldata }) {
    const baseURL = process.env.REACT_APP_ORIGIN
    const hotelURL = `${baseURL}${routeLink.HotelWithKey}`

    if (!urldata.includes(hotelURL)) {
      setError(searchingHotelMessages.INVALID)
      return
    }

    setLoading(true)

    const hotelKey = urldata.replace(hotelURL, '').replace('/', '')

    let hotelInfo = await GetHotelDataByKey(hotelKey)

    if (!hotelInfo) {
      setError(searchingHotelMessages.NOT_AVAILABLE)
      return
    }
    setUrl(urldata)
    setResStatus({ msg: '', data: hotelInfo, success: true })
    setCurrentHotelKey(dispatch, hotelKey)
    localStorage.setItem('hotel', hotelKey)
    dispatch(
      actions.setHotelInfoWithQrCode({
        hotelInfo,
      })
    )

    GetHotelData(hotelKey, dispatch, false)
    setLoading(false)
  }

  function setError(msg) {
    setScreen(3)
    setResStatus({ msg, success: false })
    setLoading(false)
  }

  useEffect(() => {
    history.replace('/')
    return () => {
      setUrl('')
      setLoading(false)
      setScreen(1)
      setResStatus({ msg: '', success: null, data: null })
    }
  }, [])

  useEffect(() => {
    let handler = 0
    if (screen === 2) {
      setResStatus({ msg: '', success: null })
      handler = setTimeout(() => {
        //console.log(url)
        if (!url) {
          //setScreen(1)
        }
      }, 50000)
    }
    return () => clearInterval(handler)
  }, [url, screen])

  useEffect(() => {
    const interval = setInterval(() => {
      if (localStorage.getItem('hotel')) {
        window.location.reload()
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])
  if (loading) return <Loader />

  const heading = (
    <div>
      <h6>Welcome to iNPLASS</h6>
      <p>Please scan the QR Code for Check In</p>
    </div>
  )

  switch (screen) {
    case 1:
      return <QRLanding setScreen={setScreen} heading={heading} />

    case 2:
      return (
        <QRCodeScanner
          heading={heading}
          setResponse={setResponse}
          setError={setError}
        />
      )
    case 3:
      return <QRError msg={resStatus.msg} setScreen={setScreen} />
    default:
      return
  }
}

export default QRScreen

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

const WelcomeLoader = () => {

  return (
    <>
      <div className="loaderContainer content">
        <div className="loaderWrapper">
          <div className="loaderRail "> </div>
        </div>
      </div>
    </>
  )
}

export default WelcomeLoader

/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable react-hooks/exhaustive-deps
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  loadProgress,
  searchingHotelMessages,
} from '../../../../constants/general'
import { GetHotelData, GetHotelDataByKey } from '../../../../services/hotels'
import { actions } from '../../../../Store'
import { Loader } from '../../Loader'
import HotelNotFound from './../../../Pages/HotelNotFound/index'
import ActionModal from './../../Modals/ActionModal'
import routeLink from '../../../../constants/routeLink'
import { setCurrentHotelKey, Ternary, isEmpty } from '../../../../config/utils'
import { YOUR_ARE_ALREADY_CHECKEDIN } from '../../../../config/Constants'
import ProductionDetailsToast from '../../ProductionDetailsToast/ProductionDetailsToast'

function onLoadCheckHotelIsAvial({ hotelInfo, props, dispatch }) {
  if (
    !Object.keys(hotelInfo).length &&
    props.routeComponentPath !== 'Hotel/Hotel'
  ) {
    window.history.replaceState('', '', '/')
  }
  dispatch(
    actions.setSearchingHotel({
      searchingHotel: loadProgress.LOADED,
      searchingHotelMessage: searchingHotelMessages.NOT_AVAILABLE,
    })
  )
  dispatch(actions.setHotelInfo({}))
}

async function loadHotelDetails({
  searchingHotel,
  hotelInfo,
  hotelKey,
  dispatch,
  GetHotelDataFromIdListener,
}) {
  if (loadProgress.LOADED !== searchingHotel) {
    GetHotelData(hotelKey, dispatch, GetHotelDataFromIdListener)
  }

  if (hotelInfo && Object.keys(hotelInfo).length) {
    dispatch(
      actions.setSearchingHotel({
        searchingHotel: loadProgress.LOADED,
        searchingHotelMessage: searchingHotelMessages.SUCCESS,
      })
    )
  }
}

function loadModalMessages({
  setModal,
  getNewHotelData,
  setAlrtMessage,
  getOldHotelData,
  guest,
  setHotelKey,
  dispatch,
}) {
  if (!getNewHotelData?.hotelName) {
    setAlrtMessage({
      msg: `Invalid Hotel URL! You will be redirected to the oldHotelName.`,
      options: {
        oldHotelName: getOldHotelData?.hotelName,
      },
    })
    setTimeout(() => {
      if (localStorage.getItem('apitoken') || guest?.id) {
        localStorage.setItem('apitoken', guest?.id)
        window.location.href = routeLink.Home
        return
      } else {
        setHotelKey(localStorage.getItem('hotel'))
      }
      dispatch(actions.setRedirect(true))
      setModal(false)
    }, 3000)
  } else {
    setAlrtMessage({
      msg: YOUR_ARE_ALREADY_CHECKEDIN,
      options: {
        oldHotelName: getOldHotelData?.hotelName,
        newHotelName: getNewHotelData?.hotelName,
      },
    })
  }
}

function HotelLayout(props) {
  const {
    modal,
    setModal,
    setHotelKey,
    hotelKey,
    onCancelClick,
    onOkClick,
    alrtMessage,
    setAlrtMessage,
    onBackButtonClick,
  } = props
  const {
    redirect,
    GetHotelDataFromIdListener,
    searchingHotel,
    hotelInfo,
    searchingHotelMessage,
    guest,
    currentHotelKey,
  } = useSelector(state => state)
  const dispatch = useDispatch()

  let getNewHotelData = null

  async function onLoad() {
    // check default url and redirect to hotel url start
    if (localStorage.getItem('hotel') && currentHotelKey) {
      if (
        localStorage.getItem('hotel') !== currentHotelKey &&
        props.routeComponentPath === 'Hotel/Hotel'
      ) {
        getNewHotelData = await GetHotelDataByKey(currentHotelKey)
        loadModalMessages({
          setModal,
          getNewHotelData,
          setAlrtMessage,
          getOldHotelData: hotelInfo,
          guest,
          setHotelKey,
          dispatch,
        })
        setHotelKey(localStorage.getItem('hotel'))
        setModal(true)
        return
      }
    }
    // check default url and redirect to hotel url end

    // if hotel is not available start
    if (!localStorage.getItem('hotel') && !currentHotelKey) {
      onLoadCheckHotelIsAvial({ hotelInfo, props, dispatch })
      return
    }
    // if hotel is not available end

    if (hotelKey || currentHotelKey) {
      let hotelKeyName = hotelKey ?? currentHotelKey
      setCurrentHotelKey(dispatch, hotelKeyName)
      loadHotelDetails({
        searchingHotel,
        hotelInfo,
        hotelKey: hotelKeyName,
        dispatch,
        GetHotelDataFromIdListener,
      })
    }
    // check hotelkey end
  }

  useEffect(() => {
    if (!redirect && !modal && loadProgress.LOADED !== searchingHotel) {
      onLoad()
    }
  }, [hotelInfo, searchingHotel])

  useEffect(() => {
    if (redirect) {
      setTimeout(() => {
        console.log('HotelLayout : 219')
        setModal(false)
        dispatch(actions.setRedirect(false))
        window.location.href = `${
          routeLink.HotelWithKey
        }/${localStorage.getItem('hotel')}`
      }, 3000)
    }
  }, [redirect])

  if (modal && alrtMessage?.msg && alrtMessage?.options) {
    console.log('alrtMessage?.options', alrtMessage?.options)
    return (
      <ActionModal
        messageText={alrtMessage.msg}
        options={alrtMessage.options}
        visible={modal}
        okButtonText={'Confirm'}
        cancelButtonText='Cancel'
        onlyOkButton={false}
        onCancelClick={onCancelClick}
        onOkClick={Ternary(
          alrtMessage?.options?.newHotelName,
          onOkClick,
          onBackButtonClick
        )}
        showButton={Ternary(
          alrtMessage?.options?.customButton,
          true,
          Ternary(alrtMessage?.options?.newHotelName, true, false)
        )}
        customButton={alrtMessage?.options?.customButton}
      />
    )
  }

  switch (searchingHotel) {
    case loadProgress.LOADED: {
      if (
        !hotelInfo ||
        isEmpty(hotelInfo) ||
        (hotelInfo && (hotelInfo?.isDelete || hotelInfo?.status === 'inactive'))
      ) {
        localStorage.clear()
        return (
          <>
            <ProductionDetailsToast />
            <HotelNotFound message={searchingHotelMessage}></HotelNotFound>
          </>
        )
      } else {
        return (
          <div>
            <ProductionDetailsToast />
            {props.children}
          </div>
        )
      }
    }
    case loadProgress.TOLOAD:
    case loadProgress.LOADING:
    default:
      return <Loader />
  }
}

export default HotelLayout

export const serviceType = {
  serviceRequest: 'ServiceRequests',
  reservation: 'Reservations',
  roomService: 'RoomService',
  medicalService: 'MedicalService',
  houseKeepingService: 'HouseKeepingService',
}
const DepartmentAndServiceKeys = {
  frontDesk: {
    key: 'front-desk',
    name: 'Front Desk',
    services: {
      changeUpgradeRoom: {
        key: 'change-upgrade-room',
        name: 'Change or Upgrade room',
        type: serviceType.serviceRequest,
      },
      extraBed: {
        key: 'extra-bed',
        name: 'Extra bed',
        type: serviceType.serviceRequest,
      },
      wakeUpCall: {
        key: 'wake-up-call',
        name: 'Wake up call',
        type: serviceType.serviceRequest,
      },
      doctorOnACall: {
        key: 'doctor-on-a-call',
        name: 'Doctor on a call',
        type: serviceType.serviceRequest,
      },
      extendStay: {
        key: 'extend-stay',
        name: 'Extend stay',
        type: serviceType.serviceRequest,
      },
      viewBill: {
        key: 'view-bill',
        name: 'View Bill',
        type: serviceType.serviceRequest,
      },
      checkoutAndRequestBill: {
        key: 'checkout-and-request-bill',
        name: 'Checkout and request bill',
        type: serviceType.serviceRequest,
      },
      airportDropoff: {
        key: 'airport-dropoff',
        name: 'Airport dropoff',
        type: serviceType.serviceRequest,
      },
    },
    type: {
      RoomUpgrade: 'RoomUpgrade',
      ScheduledTime: 'ScheduledTime',
      OtherRequest: 'OtherRequest',
    },
  },
  houseKeeping: {
    key: 'house-keeping',
    name: 'House Keeping',
    services: {
      roomCleaning: {
        key: 'room-cleaning',
        name: 'Room cleaning',
        type: serviceType.serviceRequest,
      },
      pickLaundry: {
        key: 'pick-laundry',
        name: 'Pick laundry',
        type: serviceType.serviceRequest,
      },
      cleanTray: {
        key: 'clean-tray',
        name: 'Clean tray',
        type: serviceType.serviceRequest,
      },
      complementary: {
        key: 'complementary',
        name: 'Complementary',
        type: serviceType.serviceRequest,
      },
      chargeables: {
        key: 'chargeables',
        name: 'Chargeables',
        type: serviceType.serviceRequest,
      },
      maintenance: {
        key: 'maintenance',
        name: 'Chargeable',
        type: serviceType.serviceRequest,
        services: {
          airconditioner: {
            key: 'airconditioner',
            name: 'Air Conditioner',
            type: serviceType.serviceRequest,
          },
          waterLeakage: {
            key: 'water-leakage',
            name: 'Water Leakage',
            type: serviceType.serviceRequest,
          },
          refridgerator: {
            key: 'refridgerator',
            name: 'Refrigerator',
            type: serviceType.serviceRequest,
          },
          light: {
            key: 'light',
            name: 'Light',
            type: serviceType.serviceRequest,
          },
          electric: {
            key: 'electric',
            name: 'Electric',
            type: serviceType.serviceRequest,
          },
          television: {
            key: 'television',
            name: 'Television',
            type: serviceType.serviceRequest,
          },
          others: {
            key: 'maintenance-others',
            name: 'Others',
            type: serviceType.serviceRequest,
          },
        },
      },
      maintenance2: {
        key: 'maintenance',
        name: 'Complementary',
        type: serviceType.serviceRequest,
        services: {
          airconditioner: {
            key: 'airconditioner',
            name: 'Air Conditioner',
            type: serviceType.serviceRequest,
          },
          waterLeakage: {
            key: 'water-leakage',
            name: 'Water Leakage',
            type: serviceType.serviceRequest,
          },
          refridgerator: {
            key: 'refridgerator',
            name: 'Refrigerator',
            type: serviceType.serviceRequest,
          },
          light: {
            key: 'light',
            name: 'Light',
            type: serviceType.serviceRequest,
          },
          electric: {
            key: 'electric',
            name: 'Electric',
            type: serviceType.serviceRequest,
          },
          television: {
            key: 'television',
            name: 'Television',
            type: serviceType.serviceRequest,
          },
          others: {
            key: 'maintenance-others',
            name: 'Others',
            type: serviceType.serviceRequest,
          },
        },
      },
      replacement: {
        key: 'replacement',
        name: 'Replacement',
        type: serviceType.serviceRequest,
        services: {
          minibar: {
            key: 'minibar',
            name: 'Minibar',
            type: serviceType.serviceRequest,
          },
          toiletries: {
            key: 'toiletries',
            name: 'Toiletries',
            type: serviceType.serviceRequest,
          },
          linenBed: {
            key: 'linen-bed',
            name: 'Bed Linen',
            type: serviceType.serviceRequest,
          },
          pillow: {
            key: 'pillow',
            name: 'Pillow',
            type: serviceType.serviceRequest,
          },
          others: {
            key: 'replacement-others',
            name: 'Others',
            type: serviceType.serviceRequest,
          },
        },
      },
    },
  },
  concierge: {
    key: 'concierge',
    name: 'Concierge',
    services: {
      bookTaxi: {
        key: 'book-taxi',
        name: 'Book taxi',
        type: serviceType.serviceRequest,
      },
      carRental: {
        key: 'car-rental',
        name: 'Car rental',
        type: serviceType.serviceRequest,
      },
      getMyCar: {
        key: 'get-my-car',
        name: 'Get my car',
        type: serviceType.serviceRequest,
      },
      travelDesk: {
        key: 'travel-desk',
        name: 'Travel Desk',
        type: serviceType.serviceRequest,
      },
      hotelShuttle: {
        key: 'hotel-shuttle',
        name: 'Hotel Shuttle',
        type: serviceType.serviceRequest,
      },
    },
  },
  foodAndBeverage: {
    key: 'food-and-beverage',
    name: 'Food and Beverage',
    services: {
      roomService: {
        key: 'room-service',
        name: 'Room service',
        type: serviceType.roomService,
      },
      restaurant: {
        key: 'restaurant',
        name: 'Restaurant',
        type: serviceType.reservation,
      },
    },
  },
  foodAndBeverageTwo: {
    key: 'food-and-beverage',
    name: 'Food and Beverage',
    services: {
      roomService: {
        key: 'doctor',
        name: 'Room service',
        type: serviceType.roomService,
      },
      restaurant: {
        key: 'restaurant',
        name: 'Restaurant',
        type: serviceType.reservation,
      },
    },
  },
  spaAndWellness: {
    key: 'spa-and-wellness',
    name: 'Spa and Wellness',
    services: {
      spa: {
        key: 'spa',
        name: 'Spa',
        type: serviceType.reservation,
      },
      gym: {
        key: 'gym',
        name: 'Gym',
        type: serviceType.reservation,
      },
      saloon: {
        key: 'saloon',
        name: 'Saloon',
        type: serviceType.reservation,
      },
    },
  },
  doctorOnCallService: {
    key: 'doctor-on-call',
    name: 'Doctor On Call',
  },
  promotions: {
    key: 'promotions',
    name: 'Promotions',
  },
  movieBooking: {
    key: 'movie-booking',
    name: 'Movie Booking',
  },
  eventsAround: {
    key: 'events-around',
    name: 'Events Around',
  },
  bookTaxi: {
    key: 'book-taxi',
    name: 'Book A Taxi',
  },
  aroundMe: {
    key: 'around-me',
    name: 'Around Me',
  },
  hotelInfo: {
    key: 'hotel-information',
    name: 'Hotel Information',
  },
  callTheHotel: {
    key: 'call-the-hotel',
    name: 'Call The Hotel',
  },
}

export default DepartmentAndServiceKeys

import React, { memo } from 'react'
import { Switch } from 'react-router-dom'

import routes from './routeConstants'
import HotelAuthRoute from './HotelAuthRoute'
import PrivateRoute from './PrivateRoute'
import LoadRoute from './LoadRoute'
import { accessibility } from '../constants/general'
import ConfirmationCheckPointRoute from './ConfirmationCheckPointRoute'
import PublicRoute from './PublicRoute'
import PrivateDuplicateRoute from './PrivateDuplicateRoute'
import OfflinePage from '../components/Pages/OfflinePage'

function Routes() {
  const RouteConfig = () => {
    return Object.keys(routes).map(routeKey => {
      let routeProps = {
        key: routeKey,
        render: LoadRoute,
        importPath: routes[routeKey].path,
        ...routes[routeKey],
      }
      if (!routes[routeKey].isAuthenticated) {
        if (routes[routeKey].type) {
          switch (routes[routeKey].type) {
            case accessibility.CONFIRMATION_CHECKPOINT: {
              return <ConfirmationCheckPointRoute {...routeProps} />
            }
            case accessibility.PUBLIC:
            default: {
              return <PublicRoute {...routeProps} />
            }
          }
        }
        return <HotelAuthRoute {...routeProps} />
      } else {
        if (routes[routeKey].type) {
          switch (routes[routeKey].type) {
            case accessibility.DUPLICATE_ROUTE: {
              return <PrivateDuplicateRoute {...routeProps} />
            }
            case accessibility.PUBLIC:
            default: {
              return <OfflinePage {...routeProps} />
            }
          }
        }
        return <PrivateRoute {...routeProps} />
      }
    })
  }

  return <Switch>{RouteConfig()}</Switch>
}

export default memo(Routes)

import moment from 'moment'
import firebase from 'firebase/app'
import {
  NormalLabel,
  PendingStatusLabel,
  InProgressStatusLabel,
  CurrentlyYourRequestIsInProgress,
} from '../config/Constants'

import { actions } from '../Store'
import { creationData } from './common'
import { getRequestCollection, getRequestPath } from '../config/utils'
import DepartmentAndServiceKeys from '../config/departmentAndServicekeys'

export const GetServiceRequests = async (
  hotelId,
  guestId,
  departmentId,
  serviceId
) => {
  try {
    if (!hotelId || !guestId || !departmentId || !serviceId) {
      return {
        success: false,
        message:
          'Please provide valid data to check number of pending requests!',
        pendingRequests: [],
      }
    }

    const serviceRequestsSnapshot = await getRequestCollection(
      hotelId,
      departmentId
    )
      .where('guestId', '==', guestId)
      .where('serviceId', '==', serviceId)
      .where('status', 'in', [PendingStatusLabel, InProgressStatusLabel])
      .get()

    const pendingRequests = []
    for (const request of serviceRequestsSnapshot.docs) {
      pendingRequests.push({ id: request.id, ...request.data() })
    }

    return { success: true, message: '', pendingRequests }
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
    return {
      success: false,
      message: error?.message || 'Something went wrong!',
      pendingRequests: [],
    }
  }
}

function getUpdateRequestData(requestData) {
  const { departmentKey, serviceKey } = requestData
  const frontDeskDeptService = DepartmentAndServiceKeys.frontDesk
  if (departmentKey === frontDeskDeptService.key) {
    const roomUpgrade = [frontDeskDeptService.services.changeUpgradeRoom.key]
    const scheduledTime = [
      frontDeskDeptService.services.checkoutAndRequestBill.key,
      frontDeskDeptService.services.airportDropoff.key,
      frontDeskDeptService.services.wakeUpCall.key,
    ]

    let frontDeskServiceType = ''
    if (roomUpgrade.includes(serviceKey))
      frontDeskServiceType = frontDeskDeptService.type.RoomUpgrade
    else if (scheduledTime.includes(serviceKey))
      frontDeskServiceType = frontDeskDeptService.type.ScheduledTime
    else frontDeskServiceType = frontDeskDeptService.type.OtherRequest

    requestData['frontDeskServiceType'] = frontDeskServiceType
  }
  return requestData
}

export const MakeNewServiceRequest = async requestData => {
  try {
    const noOfRequestsAllowed = requestData?.noOfRequestsAllowed || 1

    const { hotelId, guestId, departmentId, serviceId, dispatch } = requestData

    if (!departmentId || !serviceId) {
      return {
        success: false,
        message: 'Please wait! Fetching information...',
      }
    }

    const { success, message, pendingRequests } = await GetServiceRequests(
      hotelId,
      guestId,
      departmentId,
      serviceId
    )

    const restrict =
      'restrictNoOfRequests' in requestData
        ? requestData.restrictNoOfRequests
        : true

    if (restrict && pendingRequests.length >= noOfRequestsAllowed) {
      const prepend = noOfRequestsAllowed > 1 ? 's' : ''
      const responseMessage =
        noOfRequestsAllowed === 1
          ? CurrentlyYourRequestIsInProgress(requestData.service)
          : `Only ${noOfRequestsAllowed} request${prepend} allowed!`

      dispatch(
        actions.setDuplicateRequest({
          duplicateRequest: true,
          duplicateRequestServiceName: requestData.service,
        })
      )

      return {
        success: false,
        message: responseMessage,
      }
    } else {
      dispatch(
        actions.setDuplicateRequest({
          duplicateRequest: false,
          duplicateRequestServiceName: '',
        })
      )
    }

    if (!success) {
      return { success, message }
    }

    /* If FrontDesk Request will be raise then including frontDeskServiceType 
      property in the request object */
    requestData = getUpdateRequestData(requestData)
    delete requestData.restrictNoOfRequests
    delete requestData.dispatch

    const data = {
      createdAtDate: moment().startOf('day').toDate(),
      ...requestData,
      ...creationData(),
    }

    if (
      'requestedTime' in requestData &&
      moment(requestData.requestedTime).isValid()
    ) {
      data.requestedDate = firebase.firestore.Timestamp.fromDate(
        new Date(
          moment(requestData.requestedTime).format(moment.HTML5_FMT.DATE)
        )
      )
    }

    if (
      'requestedTime' in requestData &&
      moment(requestData.requestedTime).isValid()
    ) {
      data.requestedDate = firebase.firestore.Timestamp.fromDate(
        new Date(
          moment(requestData.requestedTime).format(moment.HTML5_FMT.DATE)
        )
      )
    }

    if (!data?.hasOwnProperty('menuDetail')) {
      data.cuisines = []
    }

    if (!data.requestType) {
      data.requestType = NormalLabel
    }

    const requestDoc = getRequestCollection(hotelId, departmentId).doc()
    const requestId = requestDoc.id
    const requestPath = getRequestPath(hotelId, departmentId, requestDoc.id)
    await requestDoc.set({ requestId, ...data })

    return {
      success: true,
      message: '',
      requestType: data.requestType,
      referenceId: '123',
      requestPath,
    }
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
    return {
      success: false,
      message: error?.message || 'Something went wrong! Please try again!',
    }
  }
}

import { LightgalleryItem } from 'react-lightgallery'
import { menuitemImg, dishPlaceHolderImg } from '../config/images'
import { useCustomI18NTranslatorHook } from '../utility/globalization'

export const secondsToShowAlert = 8000

export const Collections = {
  SERVICES: 'services',
  DEPARTMENTS: 'departments',
  HOTELS: 'hotels',
  ORDERS: 'orders',
  ROLES: 'roles',
  USERS: 'users',
  TOKEN: 'tokens',
  RESTAURANT: 'restaurant',
  TERMSANDCONDITIONS: 'termsAndConditions',
  PRIVACYPOLICY: 'privacyPolicy',
  CUISINES: 'cuisines',
  FOODMENU: 'foodMenu',
  PROMOTIONS: 'promotions',
  HOUSEKEEPINGSERVICES: 'houseKeepingServices',
  LANGUAGEDICTIONARY: 'languagedictionary',
  DOMAIN: 'domain',
  DEFAULT_DEPARTMENT_TO_MENUS: 'defaultDepartmentToMenus',
  TITLE_AND_PERMISSIONS: 'titleAndPermissions',
  ROOM_TYPE: 'roomType',
  GUEST: 'guest',
  CONFIG: 'config',
  CUISINEMENU: 'cuisineMenu',
  REQUEST_COUNTS: 'count',
  REQUEST_INFO: 'request_info',
  REQUEST_INFO_DEPARTMENT: 'request_info_department',
  REQUEST_INFO_DEPARTMENT_REQUEST: 'request_info_department_request',
  RELEASE: 'release',
  PMSRESERVE: 'pmsReserve',
  MOBILE_DASHBOARD: 'mobile_dashboard',
  DEPARTMENT_REQUEST_STAT: 'departmentRequestStat',
  DEPARTMENT_REQUEST: 'departmentRequest',
  GUEST_REQUEST: 'guestRequest',
}

export const PendingStatusLabel = 'Pending'
export const InProgressStatusLabel = 'In Progress'
export const CompletedStatusLabel = 'Completed'
export const RejectedStatusLabel = 'Rejected'

export const RequestStatus = {
  [PendingStatusLabel]: PendingStatusLabel,
  [InProgressStatusLabel]: InProgressStatusLabel,
  [CompletedStatusLabel]: CompletedStatusLabel,
}

export const UrgentLabel = 'Urgent'
export const NormalLabel = 'Normal'
export let requestTypeOptions = [
  { label: NormalLabel, value: NormalLabel },
  { label: UrgentLabel, value: UrgentLabel },
]

export const CustomHookTranslateData = () => {
  const [translateTextI18N] = useCustomI18NTranslatorHook()

  requestTypeOptions = [
    { label: translateTextI18N(NormalLabel), value: NormalLabel },
    { label: translateTextI18N(UrgentLabel), value: UrgentLabel },
  ]

  return [requestTypeOptions]
}
export const hoursOption = Array(24)
  .fill(0)
  .map((_, i) => {
    let hour = String(i)
    if (i < 10) hour = `0${hour}`
    return { label: hour, value: hour }
  })

export const minutesOption = Array(60)
  .fill(0)
  .map((_, i) => {
    let minute = String(i)
    if (i < 10) minute = `0${minute}`
    return { label: minute, value: minute }
  })

export const menuItemImagePlaceholder = dishPlaceHolderImg

export const CheckInStatus = 'Check In'

export const RequestIsInProcessMessage =
  'We are already proceeding with another request. Do you wish to initiate another ?'

export const CurrentlyYourRequestIsInProgress = serviceName =>
  `Your previous request for ${serviceName} is pending. We will be serving you at the earliest.`

export const UnsubscribeMessage =
  'You will not be able to receive notifications further. Do you wish to unsubscribe ?'

export const translationDataKey = 'translation'

export const PhotoItem = ({ image, group }) => (
  <div className='col-4'>
    <LightgalleryItem group={group} src={image}>
      <img src={image} style={{ width: '100%', height: '100%' }} alt='' />
    </LightgalleryItem>
  </div>
)

export const YOUR_ARE_ALREADY_CHECKEDIN = `You are already checked-in to oldHotelName, Do you really want to check-in to another Hotel newHotelName?`

export const YOUR_ARE_ALREADY_CHECKEDIN_OTHER_HOTEL_RELOAD = `You are checkin with other hotel. Please Reload this app`

export const YOUR_ARE_ALREADY_CHECKEDIN_SAME_HOTEL_RELOAD = `You are checkin with same hotel. Please Reload this app`

export const SessionExpired =
  'Your session has expired. Please reload the page.'

export const defaultModalData = {
  status: false,
  data: null,
  modalType: null,
}

export const MODAL_TYPES = {
  PDFVIEWER: 'PDFVIEWER',
}

export const GUIDELINE_TIMEFORMAT = 'DD-MM-YYYY hh:mm:ss'

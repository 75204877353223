import React, { memo, Suspense, useState } from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom'
import HotelLayout from '../components/Common/Layout/Hotel'
import { Loader } from '../components/Common/Loader'
import { AppContext } from '../config/context'
import GuestStatus from '../constants/GuestStatus'
import routeLink from '../constants/routeLink'

import { useDispatch } from 'react-redux'
import { actions } from '../Store'
import { networkStatus } from '../constants/general'
import OfflinePage from '../components/Pages/OfflinePage'
import { auth } from '../config/firebase'
import {
  ClearUserData,
  setCurrentHotelKey,
  unsubscribeFirebaseListeners,
} from '../config/utils'

function RouteComp({ render: Component, ...rest }) {
  const history = useHistory()
  const { guest, currentHotelKey } = rest.appState
  const params = {
    hotelslugname: currentHotelKey,
  }
  const [modal, setModal] = useState(false)
  const [hotelKey, setHotelKey] = useState(
    params?.hotelslugname ?? localStorage.getItem('hotel') ?? null
  )
  const [alrtMessage, setAlrtMessage] = useState({ msg: '', options: null })
  const dispatch = useDispatch()

  let props = {
    alrtMessage,
    setAlrtMessage,
    modal,
    setModal,
    setHotelKey,
    hotelKey,
    onCancelClick: () => {
      //continue to old url
      let ls_apitoken = localStorage.getItem('apitoken')
      let ls_guestId = localStorage.getItem('guestId')

      if (ls_apitoken && guest?.id) {
        history.replace(routeLink.Home)
        return
      }

      if (ls_guestId && guest?.id) {
        history.replace(routeLink.ConfirmationScreen)
        return
      }

      setHotelKey(localStorage.getItem('hotel'))
      dispatch(actions.setRedirect(true))
      setModal(false)
    },
    onOkClick: async () => {
      //continue to new url
      if (auth.currentUser) {
        await auth
          .signOut()
          .then(async () => {
            unsubscribeFirebaseListeners()
            ClearUserData({ dispatch })
          })
          .catch(err => console.log(err))
      }
      const curreDomain = params?.hotelslugname ?? rest?.appState?.subDomain

      setModal(false)
      setHotelKey(curreDomain)
      setCurrentHotelKey(dispatch, null)

      dispatch(actions.setClearHotelUserData())
      dispatch(actions.setRedirect(false))

      localStorage.setItem('hotel', curreDomain)
      history.replace(`${routeLink.Hotel}`)
    },
    onBackButtonClick: () => {
      //continue to new url
      let curreDomain = localStorage.getItem('hotel')
      const currUrl = routeLink.Hotel + '/' + curreDomain
      localStorage.setItem('hotel', curreDomain)
      setHotelKey(curreDomain)
      dispatch(actions.setClearHotelUserData())
      history.push(currUrl)
      setModal(false)
      dispatch(actions.setRedirect(false))
    },

    ...rest,
  }
  return (
    <Suspense fallback={<Loader />}>
      <HotelLayout {...props}>
        <Component {...props} />
      </HotelLayout>
    </Suspense>
  )
}

function HotelPanel(restProps) {
  return <RouteComp {...restProps} />
}

const HotelAuthRoute = hotelProps => {
  return (
    <Route
      {...hotelProps}
      render={props => {
        return (
          <AppContext.Consumer>
            {context => {
              const { online, guest, currentHotelKey, subDomain } =
                context.appState

              let wCurrentHotelKey = window.location.pathname
                .replace('/hotel/', '')
                .replace('/', '')

              let ls_apitoken = localStorage.getItem('apitoken')
              let ls_hotel = localStorage.getItem('hotel')
              let ls_guestId = localStorage.getItem('guestId')

              if (online === networkStatus.FAILED) {
                return <OfflinePage />
              }
              if (
                ls_apitoken &&
                ls_hotel &&
                !ls_guestId &&
                ls_hotel !== subDomain
              ) {
                localStorage.removeItem('apitoken')
              }

              if (
                ls_apitoken &&
                ls_hotel &&
                !ls_guestId &&
                'status' in guest &&
                guest?.status === GuestStatus.CheckIn &&
                ls_hotel === currentHotelKey
              ) {
                return <Redirect to={routeLink.Home} />
              } else if (
                ls_guestId &&
                ls_hotel &&
                !ls_apitoken &&
                'status' in guest &&
                ls_hotel === (currentHotelKey ?? wCurrentHotelKey)
              ) {
                return <Redirect to={routeLink.ConfirmationScreen} />
              } else {
                return (
                  <HotelPanel
                    {...props}
                    {...hotelProps}
                    {...context}
                  ></HotelPanel>
                )
              }
            }}
          </AppContext.Consumer>
        )
      }}
    />
  )
}

export default memo(HotelAuthRoute)

/* eslint-disable jsx-a11y/alt-text */

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { networkStatus } from '../../../constants/general'
import routeLink from '../../../constants/routeLink'
import { history } from '../../../history'
import PoweredByInplass from '../../Common/PoweredByInplass/PoweredByInplass'

function OfflinePage() {
  const { online } = useSelector(state => state)

  useEffect(() => {
    if (online !== networkStatus.FAILED) {
      if (localStorage.getItem('hotel')) {
        history.push(routeLink.Hotel)
      }
    }
  }, [online])

  return (
    <div>
      <div className='success-wrp thankyou-wrp'>
        <div className='container'>
          <h5 className='mb-0'>You're offline</h5>
          <h5 className='mb-0'>Check your connection and try again</h5>
        </div>
      </div>
      <div className='footer-wrp'>
        <PoweredByInplass />
      </div>
    </div>
  )
}
export default OfflinePage

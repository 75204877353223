import React, { useEffect, useState } from 'react'
import ActionModal from './components/Common/Modals/ActionModal'

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false)
  const [promptInstall, setPromptInstall] = useState(null)
  const [hide, setHide] = useState(true)

  // useEffect(() => {
  //   if (window.matchMedia('(display-mode: standalone)').matches) {
  //     // Check for app alredy installed or not
  //     disablePrompt()
  //   }
  // }, [promptInstall, hide])

  const disablePrompt = () => {
    setHide(true)
    setPromptInstall(null)
  }

  useEffect(() => {
    const handler = e => {
      e.preventDefault()
      console.log('we are being triggered handler:D')
      setSupportsPWA(true)
      setHide(false) // Show Custom Prompt
      setPromptInstall(e)
      window.removeEventListener('beforeinstallprompt', handler)
    }
    window.addEventListener('beforeinstallprompt', handler)
    window.addEventListener('appinstalled', disablePrompt)
  }, [])

  const onClick = async evt => {
    evt.preventDefault()
    if (!promptInstall) {
      return
    }
    promptInstall.prompt()
    const { outcome } = await promptInstall.userChoice
    // Optionally, send analytics event with outcome of user choice
    console.log(`User response to the install prompt: ${outcome}`)
    // We've used the prompt, and can't use it again, throw it away
    disablePrompt()
  }
  if (!supportsPWA) {
    return null
  }
  return (
    <>
      {!hide && (
        <ActionModal
          messageText='Add to Home screen'
          visible={true}
          okButtonText='Add'
          cancelButtonText='Cancel'
          onlyOkButton={false}
          onCancelClick={() => setHide(true)}
          onOkClick={onClick}
          showButton={true}
        />
      )}
    </>
  )
}
export default InstallPWA

import Axios from '../utility/axiosHelper'
import URL from '../constants/apiUrls'
import { Collections } from '../config/collection'
import { auth, db } from '../config/firebase'
import { actions } from '../Store'
import { GetCurrentUserToken } from './guest'
import { unsubscribeList } from '../config/utils'

export const DeletePlayerId = async (
  userType,
  deviceType,
  userid,
  playerid,
  token
) => {
  Axios.post(
    URL.DELETE_PLAYER_ID,
    {
      userType: userType,
      deviceType: deviceType,
      userid: userid,
      playerid: playerid,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then(response => {
      console.log(response)
      return true
    })
    .catch(err => {
      console.log(err)
    })
}

export const AddPlayerId = async (
  userType,
  deviceType,
  userid,
  playerid,
  currentLanguage
) => {
  const token = await GetCurrentUserToken()
  Axios.post(
    URL.ADD_PLAYER_ID,
    { userType, deviceType, userid, playerid },
    {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept-Language': currentLanguage,
      },
    }
  )
    .then(response1 => {
      console.log(response1)
    })
    .catch(err => {
      throw err
    })
}

export const GuestRequest = async data => {
  const token = await GetCurrentUserToken()
  Axios.post(URL.GUEST_REQUEST, data, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept-Language': data.currentLanguage,
    },
  })
    .then(response1 => {
      console.log(response1)
    })
    .catch(err => {
      throw err
    })
}

export const AddNotificationListener = ({
  guestId = '',
  isNotificationListener,
  dispatch,
}) => {
  try {
    const collectionKey = `MaintenanceListerner${Collections.NOTIFICATIONS}${guestId}`
    if (
      isNotificationListener ||
      !auth?.currentUser ||
      !guestId ||
      unsubscribeList?.[collectionKey]
    )
      return

    dispatch(actions.setNotificationListener(true))

    const unSub = db
      .collection(Collections.NOTIFICATIONS)
      .where('receiverId', '==', guestId)
      .orderBy('readStatus')
      .orderBy('createdAt', 'desc')
      .onSnapshot(notificationRef => {
        const notificationLists = []
        for (const notification of notificationRef.docs) {
          notificationLists.push({
            id: notification.id,
            ...notification.data(),
          })
        }
        dispatch(actions.setNotification(notificationLists))
      })

    unsubscribeList[collectionKey] = unSub
  } catch (error) {
    console.log(error)
  }
}
export const UpdateNotification = (id, readStatus, guestId) => {
  db.collection(Collections.NOTIFICATIONS).doc(id).update({
    readStatus: readStatus,
    updatedAt: new Date(),
    updatedBy: guestId,
  })
}
